import "../../styles/app.scss";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import ClientDashcard from "../../common-component/ClientDashcard";
import MatchingjobCard from "../../common-component/clientMatchingjobCard";
import GoToTop from "../common/GoToTop";
import { getPermanentDataClient } from "../../api/client/permanentDataClient";
import { toTitleCase } from "../../function/common/convertToTitleCase";
import { recgetPermanentDataClient } from "../../api/recruiter/client/permanentDataClient";
import { freegetPermanentDataClient } from "../../api/freelance/client/permanentDataClient";

const DashboardView = () => {
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  // permanent data
  const [information, setInformation] = useState({
    clientName: "",
    companyName: "",
    clientId: "",
    corporateEmail: "",
    location: "",
    workPhone: "",
  });

  useEffect(() => {
    async function callItSelf() {
      try {
        const response = isrecruiterCandidate
          ? await recgetPermanentDataClient(token)
          : isFreelance
            ? await freegetPermanentDataClient(token)
            : await getPermanentDataClient(token);
        if (response.status === 200) {
          const payload = await response.json();
          if (payload.payload.location.length > 20) {
            payload.payload.location = payload.payload.location + "...";
          } else if (
            payload.payload.companyName &&
            payload.payload.companyName.length > 20
          ) {
            payload.payload.companyName = payload.payload.companyName + "...";
          }
          setInformation({ ...information, ...payload.payload });
        }
      } catch { }
    }
    callItSelf();
  }, []);

  // return dashboard view for existing user of client

  try {
    document.getElementById("postjobid").style.display = "inline-block";
    document.getElementById("replacetitleimg").innerText = "Dashboard";
  } catch { }

  return (
    <div className={"col mx-lg-3 mx-1"}>
      <div className={"col-lg-12"}>
        <div className={"row"}>
          <div className={"col-lg-12 p-0"}>
            <div className={"row viewalignment pb-3"}>
              <ClientDashcard />
            </div>
          </div>
          {/*permanent data*/}
          <div className={"d-none col-xl-3 pt-4"}>
            <div
              className={"p-2 pb-3 border-radius-20px background-colored-primary-white"}

            >
              <div className={"row"}>
                <div className={"col-md"}>
                  <div className={"float-right"}>
                    <span
                      className={"pt-1 pb-1 pl-2 pr-2 dashboardview-rating-css"}

                    >
                      <label className="colored-Bright-orange">&#9733; 4.3</label>
                    </span>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <div className={"row"}>
                  <div className={"col-md"}>
                    <label className={"dashboardfontusername"}>
                      {information.firstName &&
                        information.firstName.charAt(0).toUpperCase() +
                        information.firstName.slice(1).toLowerCase()}
                      &nbsp;
                      {information.middleName === "null"
                        ? ""
                        : information.middleName &&
                        information.middleName.charAt(0).toUpperCase() +
                        information.middleName.slice(1).toLowerCase()}
                      &nbsp;
                      {information.lastName &&
                        information.lastName.charAt(0).toUpperCase() +
                        information.lastName.slice(1).toLowerCase()}
                    </label>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-md"}>
                    <label
                      className="colored-Purple-Indigo font-size-14px font-weight-500"
                    >
                      {information.companyName &&
                        toTitleCase(information.companyName)}
                    </label>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-md pt-0"}>
                    <label className="staticdatacommonfont">
                      {information.clientId}
                    </label>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-md"}>
                    <label className="dashboardfontusername">
                      Permanent Data
                    </label>
                  </div>
                </div>
                <div className={"row"}>
                  <div
                    className={"col-md colored-Slate-gray dashboardView-email-css"}

                  >
                    <label className="staticdatacommonfont">
                      Email:{information.corporateEmail}
                    </label>
                  </div>
                </div>
                <div className={"row mt-3"}>
                  <div className={"col-3 pl-5 ml-3"}>
                    <img
                      src="../icon/candidate/dashboard/whitelocation.png"
                      alt={"whitelocationicon"}
                      className={"pl-2 pr-2 pt-2 pb-2 background-colored-Sunset-orange border-radius-10px"}

                    />
                  </div>
                  <div className={"col mt-2"}>
                    <div
                      className="font-size-14px font-weight-500 colored-Graphite-gray dashboardView-location-css"

                    >
                      {toTitleCase(information.location)}
                    </div>
                  </div>
                </div>
                <div className={"row mt-3"}>
                  <div className={"col-3 pl-5 ml-3"}>
                    <img
                      src="../icon/candidate/dashboard/call.png"
                      alt={"call"}
                      className={"pl-2 pr-2 pt-2 pb-2 background-colored-Lavender-mist border-radius-10px"}

                    />
                  </div>
                  <div className={"col pt-2 pl-0"}>
                    <div
                      className="font-weight-500 font-size-14px colored-Graphite-gray "

                    >
                      {information.workPhone}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={"row viewalignment"}>
        <MatchingjobCard />
      </div>
      <GoToTop />
    </div>
  );
};

export default React.memo(DashboardView);
