import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { clearFilter } from "../common/clearFilter";
import { selectAllData } from "../common/selectAllData";
import {
  handleRemoveShortlistIcon,
  handleShortlistIcon,
  SelectSingle,
} from "../common/SingleSelectMatchingJob";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import { useMediaQuery } from 'react-responsive';
import { Puff } from 'react-loader-spinner'


var listData = [];

const option = { month: 'short', day: 'numeric', year: 'numeric' };

const Data = (props) => {
  // const dummyData = [
  //   {
  //     uuid: "1",
  //     jobRequirementId: "JDSMA24060003",
  //     jobRequirementUUID: "JRU-001",
  //     JobRequirement: {
  //       interviewType: "Online",
  //       domain: "Software",
  //       employementType: "Full-time",
  //       rate: 50,
  //       jobTitle: "React JS",
  //       location: "Holtsville",
  //     },
  //     matchPercentage: 85,
  //     clientUUID: "client-001",
  //     recruiterUuid: null,
  //     freelanceRecruiterUuid: null,
  //     createdAt: "2024-01-01T00:00:00Z",
  //     status: "Applied",
  //     applied: true,
  //     candidateShortlisted: true,
  //   },
  //   {
  //     uuid: "2",
  //     jobRequirementId: "DSMA24060003",
  //     jobRequirementUUID: "JRU-002",
  //     JobRequirement: {
  //       interviewType: "Online",
  //       domain: "Software",
  //       employementType: "Part-time",
  //       rate: 30,
  //       jobTitle: "React JS",
  //       location: "Holtsville",
  //     },
  //     matchPercentage: 75,
  //     clientUUID: null,
  //     recruiterUuid: "recruiter-001",
  //     freelanceRecruiterUuid: null,
  //     createdAt: "2024-02-01T00:00:00Z",
  //     status: "Apply",
  //     applied: false,
  //     candidateShortlisted: true,
  //   },
  //   // Add more dummy data as needed
  // ];

  // return matching job table data group which generated by data variable
  const [list, setList] = useState([]);
  // const [data,setMatch] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    async function callItSelf() {
      setLoading(true);
      try {
        if (props.item.length !== 0) {
          const options = {
            year: "numeric",
            month: "long",
            day: "numeric",
          };
          setList(
            props.item.map((obj) => {
              return {
                uuid: obj.uuid,
                id: [
                  obj.jobRequirementId,
                  new Date(obj.createdAt).toLocaleDateString("en-US", options),
                ],
                jobRequirementUUID: obj.jobRequirementUUID,
                jobposted: obj.JobRequirement.interviewType,
                matching: [
                  obj.matchPercentage,
                  obj.JobRequirement.domain,
                  obj.JobRequirement.employementType,
                ],
                clientSource: obj.clientUUID,
                recruiterSource: obj.recruiterUuid,
                freelanceSource: obj.freelanceRecruiterUuid,
                rate: obj.JobRequirement.rate,
                position: obj.JobRequirement.jobTitle,
                Location: obj.JobRequirement.location,
                status:
                  obj.status === null
                    ? obj.applied
                      ? "Applied"
                      : "Apply"
                    : obj.status,
                shortlistData: obj.candidateShortlisted,
              };
            })
          );
          setLoading(false);
        }
        if (props.record === 0) {
          setLoading(false);
        }
      } catch { }
    }
    callItSelf();
    listData = list;
  }, [props.item]);

  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1150px)' });
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1151px)' });
  return (
    <>
      {loading ? (
        // <div
        //   className="row h-100 matching-job-content-loader-padding"

        // >
        //   <div className="col-sm-12 my-auto">
        //     <img
        //       src={"/icon/Gif/loader.gif"}
        //       className={"mx-auto d-block"}
        //       alt={"loader"}
        //     />
        //   </div>
        // </div>
        <div className="loader">
          <div className="svg-wrapper">    <Puff
            height="80"
            width="80"
            color="#3D007A"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          /></div>

        </div>
      ) : (
        <>
          {/* {props.record === 0 ? ( */}
          {/* <> */}
          {isTabletOrMobile && <div className="row">
            {list.map((data) => (
              <div className="col-md-6" key={data.uuid && data.uuid}>
                <div className="matchingJobCard p-3 mt-3">
                  <div className="mb-0 d-flex flex-row justify-content-between">
                    <div className="inline-block font-weight-medium ">
                      {/* {cardName === "archive" ? ( */}
                      {data.status !== null && data.status !== "Apply" ? (
                        <Link
                          to={`/candidate/jobapply/${data.uuid}/${data.status}`}
                          className={"matchingjobdatalink font-size-20px"}
                        >
                          {data.position}
                        </Link>
                      ) : (
                        <Link
                          to={`/candidate/jobapply/${data.uuid}`}
                          className={"matchingjobdatalink font-size-20px"}
                        >
                          {data.position}
                        </Link>
                      )}

                    </div>
                    <div className="d-flex">
                      <div className="font-weight-medium colored-Steel-gray matchingjob-cretaedAt-width font-size-18px" >
                        {new Date(data.id[1]).toLocaleDateString(
                          "en-US",
                          option
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-0 d-inline-block font-weight-medium font-size-18px color-dark-gray" >
                    {data.status !== null && data.status !== "Apply" ? (
                      <Link
                        to={`/candidate/jobapply/${data.uuid}/${data.status}`}
                        className={"matchingjobdatalink font-size-20px"}
                      >
                        {data.id[0]}
                      </Link>
                    ) : (
                      <Link
                        to={`/candidate/jobapply/${data.uuid}`}
                        className={"matchingjobdatalink font-size-20px"}
                      >
                        {data.id[0]}
                      </Link>
                    )}
                  </div>
                  <br />

                  <div className="mt-2 d-flex d-flex-row justify-content-between">
                    <div className="d-inline-block font-weight-medium font-size-18px color-dark-gray" >
                      Interview Type : {data.jobposted}
                    </div>

                  </div>
                  <div className="mt-2 d-flex d-flex-row justify-content-between">
                    <div className="d-inline-block font-weight-medium font-size-18px color-dark-gray" >
                      Location : {data.Location}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">        <div className="mt-2 d-flex d-flex-row justify-content-between">
                      <div className="d-inline-block font-weight-medium font-size-18px color-dark-gray" >
                        Status : {data.status !== null && data.status !== "Apply" ? (
                          <div className="my-0 d-inline-block ml-2 ">
                            <Link
                              to={`/candidate/jobapply/${data.uuid}/${data.status}`}
                              className={"btn font-family-roboto font-size-09rem font-weight-normal background-colored-Lavender-slate colored-primary-white border-radius-05rem matching-job-content-status "}
                            >
                              {data.status}
                            </Link>
                          </div>

                        ) : (
                          <div className="my-0 d-inline-block ml-2 ">

                            <Link
                              to={`/candidate/jobapply/${data.uuid}`}
                              className={"btn border-radius-05rem colored-primary-white  font-weight-normal font-size-09rem font-family-roboto background-colored-Lavender-slate matching-job-content-status"}
                            >
                              {data.status}
                            </Link>
                          </div>

                        )}
                      </div>

                    </div></div>
                    <div className="col-3">
                      {/* {data.applied && (
                        <font
                          className={"matchigjobdata border-radius-05rem font-family-roboto font-size-09rem font-weight-normal application-content-applied-btn"}

                        >
                          Applied
                        </font>

                      )} */}
                    </div>
                  </div>

                  <div className="row">
                    <div className="mb-2 ml-3 d-inline-block font-weight-medium font-size-18px color-dark-gray" >
                      Rate : ${data.rate}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-5">
                      <div className=" d-flex d-flex-row">
                        <div className="d-flex align-items-center justify-content-center font-weight-medium color-dark-gray font-size-18px ">
                          Matching
                        </div>
                        <div className="flex p-2">
                          <div className="matchingJob-progressbar-width" style={{ width: '03rem' }}>
                            <CircularProgressbar
                              value={data.matching[0]}
                              text={`${data.matching[0]}%`}
                              styles={buildStyles({
                                textColor: "#000",
                                pathColor: "#5B4FB7",
                                trailColor: "#f9f9f9",
                              })}
                            />

                          </div>
                        </div>

                      </div>

                    </div>
                    <div className="p-2 py-3 col-7 ml-1 ml-sm-0">
                      {/* <div className="matchingJob-progressbar-width" style={{ width: '03rem' }}> */}
                      {data.matching[1]}
                      {/* {data.JobRequirement.domain} */}
                      {/* </div> */}
                      <div className="text-secondary">
                        <font
                          className="colored-lavender-gray font-size-08rem font-weight-normal"
                        >
                          {data.matching[2]}
                          {/* {data.JobRequirement.employementType} */}
                        </font>
                      </div>
                    </div>


                  </div>
                </div>
              </div>
            ))}
          </div>
          }
          {isDesktopOrLaptop && list.map((data, index) => (
            <div
              key={index}
              className={"row pl-3 matching-job-list-Data d-flex align-items-center justify-content-center"}
            >
              <div>
                {data.shortlistData ? (
                  <>
                    <label

                      className={" colored-Bright-orange font-size-22px"}
                      onClick={(e) => {
                        handleShortlistIcon(e);
                        props.handleremoveSingleShortlist(data.uuid);
                      }}
                    >
                      &#9733;
                    </label>
                    <label

                      className={" colored-primary-black font-weight-500 matching-job-content-uuid-Data"}
                      onClick={(e) => {
                        handleRemoveShortlistIcon(e);
                        props.handleaddSingleShortlist(data.uuid);
                      }}
                    >
                      &#9734;
                    </label>
                  </>
                ) : (
                  <>
                    <label

                      className={"colored-Bright-orange matching-job-content-uuid-Data-Or font-size-22px"}
                      onClick={(e) => {
                        handleShortlistIcon(e)
                        props.handleremoveSingleShortlist(data.uuid);
                      }}
                    >
                      &#9733;
                    </label>
                    <label

                      className={" colored-primary-black  font-size-24px font-weight-500"}
                      onClick={(e) => {
                        handleRemoveShortlistIcon(e);
                        props.handleaddSingleShortlist(data.uuid);
                      }}
                    >
                      &#9734;
                    </label>
                  </>
                )}
              </div>
              <input
                type={"checkbox"}
                className={"checkbx checkbxsingle checkboxcustomcss  mr-4 ml-3"}
                onClick={(e) => {
                  {
                    SelectSingle(e);
                    props.handleShortlist(e, data.shortlistData);
                  }
                }}
                value={data.uuid}
              />
              <div className={"col py-3 box"}>
                {/*heading box data*/}
                <div className={"row align-items-center"}>
                  <div className={"col-1 pl-0 pr-0"}>
                    {data.status !== null && data.status !== "Apply" ? (
                      <Link
                        to={`/candidate/jobapply/${data.uuid}/${data.status}`}
                        className={"matchingjobdatalink "}
                      >
                        {data.id[0]}
                      </Link>
                    ) : (
                      <Link
                        to={`/candidate/jobapply/${data.uuid}`}
                        className={"matchingjobdatalink "}
                      >
                        {data.id[0]}
                      </Link>
                    )}
                    <br />
                    <font className={"matchingjobdata"}> {new Date(
                      data.id[1]
                    ).toLocaleDateString("en-US", option)}</font>

                  </div>
                  <div className={"col-md-2"}>
                    <div
                      className="ml-5 matching-job-word-Wrap-and-white-space"
                    >
                      {data.status !== null && data.status !== "Apply" ? (
                        <Link
                          to={`/candidate/jobapply/${data.uuid}/${data.status}`}
                          className={"matchingjobdatalink "}
                        >
                          {data.position}
                        </Link>
                      ) : (
                        <Link
                          to={`/candidate/jobapply/${data.uuid}`}
                          className={"matchingjobdatalink "}
                        >
                          {data.position}
                        </Link>
                      )}
                    </div>
                  </div>
                  <div className="col-2">
                    <div className={"row"}>
                      <div className="col-5 d-flex justify-content-center align-items-center ">

                        <CircularProgressbar
                          value={data.matching[0]}
                          text={`${data.matching[0]}%`}
                          styles={buildStyles({
                            textColor: "#000",
                            pathColor: "#5B4FB7",
                            trailColor: "#f9f9f9",
                          })}
                        />
                      </div>
                      <div className="col-7 pl-0">
                        <div className={"row"}>
                          <div
                            className="col-md matching-job-word-Wrap-and-white-space "
                          >
                            <font
                              className="font-weight-semibold colored-Lavender-slate "
                            >
                              {data.matching[1]}
                            </font>
                          </div>
                        </div>
                        <div className={"row"}>
                          <div className={"col-md"}>
                            <font
                              className="font-size-08 font-weight-normal colored-Lavender-slate"
                            >
                              {data.matching[2]}
                            </font>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-1">
                    <font className={"matchingjobdata"}>{data.clientSource ? "client" : data.freelanceSource ? "Freelance" : data.recruiterSource ? "Recruiter" : ""}</font>
                  </div>
                  <div className={"col-1 pl-4"}>
                    <font className={"matchingjobdata"}>${data.rate}</font>
                  </div>
                  <div className={"col-1 ml-3"}>
                    <font className={"matchingjobdata ml-1"}>
                      {data.jobposted}
                    </font>
                  </div>
                  <div className="col-2 pl-0">
                    <div
                      className={"ml-5 pl-3 matching-job-word-Wrap-and-white-space"}
                    >
                      <font className={"matchingjobdata"}>
                        {data.Location}
                      </font>
                    </div>
                  </div>
                  <div className={"col-1 ml-3"}>
                    {data.status !== null && data.status !== "Apply" ? (
                      <Link
                        to={`/candidate/jobapply/${data.uuid}/${data.status}`}
                        className={"btn font-family-roboto font-size-09rem font-weight-normal background-colored-Lavender-slate colored-primary-white border-radius-05rem matching-job-content-status"}
                      >
                        {data.status}
                      </Link>
                    ) : (
                      <Link
                        to={`/candidate/jobapply/${data.uuid}`}
                        className={"btn border-radius-05rem colored-primary-white  font-weight-normal font-size-09rem font-family-roboto background-colored-Lavender-slate matching-job-content-status"}
                      >
                        {data.status}
                      </Link>
                    )}
                  </div>
                  {/* <div className={"col pl-5 ml-3"}>
            <div className={"row pl-4"}>
              <div className={"col-5"}>
              <div className="ml-3" style={{ wordWrap: "break-word", whiteSpace: "pre-wrap"}}>
                <font className={"matchingjobdata"}>{data.Location}zuxcujzuihsuhxujhujxsxdijdsijksdujksd</font>
                </div>
              </div>
              <div className={"col ml-1"}>
                <div className={"row align-items-center"}>
                  {
                    data.status !== null && data.status !== "Apply"
                    ?
                    <Link
                    to={`/candidate/jobapply/${data.uuid}/${data.status}`}
                    className={"btn bg-success"}
                    style={{
                      fontWeight: "500",
                      fontSize: "14px",
                      width: "100px",
                      borderRadius: "10px",
                      color: "white",
                    }}
                  >
                    {data.status}
                  </Link>
                  :
                  <Link
                  to={`/candidate/jobapply/${data.uuid}`}
                  className={"btn bg-success"}
                  style={{
                    fontWeight: "500",
                    fontSize: "14px",
                    width: "100px",
                    borderRadius: "10px",
                    color: "white",
                  }}
                >
                  {data.status}
                </Link>
                  }
                </div>
              </div>
              </div>
          </div> */}
                </div>
              </div>
            </div>
          ))}
          {/* </> */}
          {/* ) : (
            <div className="d-flex justify-content-center pt-5 pb-5">
              No Data Found
            </div>
          )} */}
        </>
      )
      }
    </>
  );
};
const data = listData;
export { Data, data, selectAllData, clearFilter };
