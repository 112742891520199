import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { clearFilter } from "../common/clearFilter";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import { useMediaQuery } from 'react-responsive';
import { Puff } from 'react-loader-spinner'


// const currentDate = new Date();
const option = { month: 'short', day: 'numeric', year: 'numeric' };

// const formattedDate = currentDate.toLocaleDateString('en-US', option);
// export const jobData = [
//   {
//     uuid: 1,
//     jobRequirementId: 'DSMA24060003',
//     JobRequirement: {
//       createdAt: '2024-08-05',
//       jobTitle: 'React Js',
//       domain: 'Software',
//       employementType: 'Frontend developer',
//       rate: 56,
//       interviewType: 'online',
//       location: 'Holtsville',
//       rateType: 'hourly', // Added rateType for completeness
//     },
//     ClientPrimarySkills: [
//       { ClientPrimarySkill: 'React Js' },
//     ],
//     ClientSecondarySkills: [
//       { ClientSecondarySkill: 'JavaScript' },
//     ],
//     AcademicCertificates: [
//       {
//         academicsQualification: 'Bachelor of Science',
//         specialization: 'Computer Science'
//       },

//     ],
//     securityClearanceRequired: 'YES',
//     securityClearanceClassification: 'Top Secret', // Example classification
//     securityClearanceCategory: 'Category A', // Example category
//     description: 'A job description for a React Js frontend developer position.',
//     status: 'On Hold',
//     matchPercentage: 78,
//     applied: true
//   },
//   {
//     uuid: 2,
//     jobRequirementId: 'DSMA24060003',
//     JobRequirement: {
//       createdAt: '2024-08-05',
//       jobTitle: 'React Js',
//       domain: 'Software',
//       employementType: 'Frontend developer',
//       rate: 56,
//       interviewType: 'online',
//       location: 'Holtsville',
//       rateType: 'hourly',
//     },
//     ClientPrimarySkills: [
//       { ClientPrimarySkill: 'React Js' },
//     ],
//     ClientSecondarySkills: [
//       { ClientSecondarySkill: 'HTML' },
//     ],
//     AcademicCertificates: [
//       {
//         academicsQualification: 'Bachelor of Engineering',
//         specialization: 'Information Technology'
//       },

//     ],
//     securityClearanceRequired: 'NO',
//     securityClearanceClassification: '',
//     securityClearanceCategory: '',
//     description: 'A job description for a React Js frontend developer position.',
//     status: 'Scheduled',
//     matchPercentage: 85,
//     applied: true
//   },
//   {
//     uuid: 3,
//     jobRequirementId: 'DSMA24060004',
//     JobRequirement: {
//       createdAt: '2024-08-05',
//       jobTitle: 'React Js',
//       domain: 'Software',
//       employementType: 'Frontend developer',
//       rate: 56,
//       interviewType: 'online',
//       location: 'Holtsville',
//       rateType: 'hourly',
//     },
//     ClientPrimarySkills: [
//       { ClientPrimarySkill: 'React Js' },
//     ],
//     ClientSecondarySkills: [
//       { ClientSecondarySkill: 'TypeScript' },
//     ],
//     AcademicCertificates: [
//       {
//         academicsQualification: 'Bachelor of Science',
//         specialization: 'Information Systems'
//       }
//     ],
//     securityClearanceRequired: 'YES',
//     securityClearanceClassification: 'Confidential',
//     securityClearanceCategory: 'Category B',
//     status: 'Offered',
//     matchPercentage: 90,
//     description: 'A job description for a React Js frontend developer position.',
//     applied: true
//   },
//   {
//     uuid: 4,
//     jobRequirementId: 'DSMA24060005',
//     JobRequirement: {
//       createdAt: '2024-08-05',
//       jobTitle: 'React Js',
//       domain: 'Software',
//       employementType: 'Frontend developer',
//       rate: 56,
//       interviewType: 'online',
//       location: 'Holtsville',
//       rateType: 'hourly',
//     },
//     ClientPrimarySkills: [
//       { ClientPrimarySkill: 'React Js' },
//     ],
//     ClientSecondarySkills: [
//       { ClientSecondarySkill: 'GraphQL' },
//     ],
//     AcademicCertificates: [
//       {
//         academicsQualification: 'Bachelor of Science',
//         specialization: 'Software Engineering'
//       }
//     ],
//     securityClearanceRequired: 'NO',
//     securityClearanceClassification: '',
//     securityClearanceCategory: '',
//     description: 'A job description for a React Js frontend developer position.',
//     status: 'Rejected',
//     matchPercentage: 70,
//     applied: true
//   }
// ];


const Data = (props) => {
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1150px)' });
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1151px)' });
  function getStatusColor(status) {
    switch (status) {
      case 'On Hold':
        return '#FFDB00';
      case 'Scheduled':
        return '#06D001';
      case 'Offered':
        return '#FF8F00';
      case 'Rejected':
        return '#FF0000';
      default:
        return ''; // or a default color if needed
    }
  }
  const [loading, setLoading] = useState(false);


  const [match, setMatch] = useState([]);
  useEffect(() => {
    try {
      setLoading(true);
      if (props.item.length !== 0) {
        setMatch(props.item);
        setLoading(false);
      }
      if (props.record === 0) {
        setLoading(false);
      }
    } catch { }
  }, [props.item]);

  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  console.log(match);
  // return matching job table data group which generated by data variable
  return (
    <>
      {loading ? (
        // <div
        //   className="row h-100 background-colored-Pale-gray application-content-loader"

        // >
        //   <div className="col-sm-12 my-auto">
        //     <img
        //       src={"/icon/Gif/loader.gif"}
        //       alt={"loader"}
        //       className={"mx-auto d-block"}
        //     />
        //   </div>
        // </div>
        <div className="loader">
          <div className="svg-wrapper">    <Puff
            height="80"
            width="80"
            color="#3D007A"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          /></div>

        </div>
      ) : (
        <>
          {props.record !== 0 ? (
            <>
              {isTabletOrMobile && <div className="row">
                {match.map((match) => (
                  <div className="col-md-6" key={match.uuid && match.uuid}>
                    <div className="matchingJobCard p-3 mt-3">
                      <div className="mb-0 d-flex flex-row justify-content-between">
                        <div className="inline-block font-weight-medium ">
                          {/* {cardName === "archive" ? ( */}
                          <Link
                            to={`/candidate/jobapply/${match.uuid}/Applied`}
                            className={"matchingjobdatalink font-size-20px"}

                          // onClick={() => clientArchiveRedirect(dispatch)}
                          >
                            {match.JobRequirement.jobTitle}

                          </Link>
                          {/* ) : (
                            <Link
                              to={`/client/filteredapplications?id=${match.uuid}`}
                              className={"matchingjobdatalink font-size-20px"}
                              // onClick={() => clientRedirect(dispatch)}
                            >
                              {match.jobTitle}
                            </Link> */}
                          {/* )} */}
                        </div>
                        <div className="d-flex">
                          <div className="font-weight-medium colored-Steel-gray matchingjob-cretaedAt-width font-size-18px" >
                            {new Date(match.JobRequirement.createdAt).toLocaleDateString(
                              "en-US",
                              option
                            )}
                          </div>





                          {/* <i class="fa fa-ellipsis-h pl-3" aria-hidden="true" aria-describedby="popup-8"
                          style={{ fontSize: '16px', color: '#F24E1E', cursor: 'pointer' }} ></i> */}
                        </div>
                        {/* <div
                          className={`modal fade ${props.archiveModal ? "show" : ""
                            }`}
                          id="deactivateprofile"
                          data-backdrop={"static"}
                          style={{
                            display: `${props.archiveModal ? "block" : "none"}`,
                          }}>
                          <div>
                            <div
                              className="modal-dialog matchingjob-modal-border"

                            >
                              <div className="modal-content">
                                <div className="modal-title pr-3 pt-3">
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    onClick={props.hideModal}
                                  >
                                    &times;
                                  </button>
                                </div>
                                <div className="modal-body pl-5 ml-3 matchingjobdata">
                                  {props.archiveErrmsg} <br />
                                  you want to archive the requirement
                                </div>
                                <div className="row pt-3 pb-4 pl-5 ml-5">
                                  <div className="col-md-5">
                                    <button
                                      type="button"
                                      className="btn btn-success py-1"
                                      onClick={() =>
                                        props.handleAssociatedRequirement(
                                          props.archiveId
                                        )
                                      }
                                      data-dismiss="modal"
                                    >
                                      Yes
                                    </button>
                                  </div>
                                  <div className="col-md-3">
                                    <button
                                      type="button"
                                      className="btn btn-danger py-1"
                                      data-dismiss="modal"
                                      onClick={props.hideModal}
                                    >
                                      No
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                      <div className="mt-0 d-inline-block font-weight-medium font-size-18px color-dark-gray" >
                        <Link
                          to={`/candidate/jobapply/${match.uuid}/Applied`}
                          className={"matchingjobdatalink font-size-18px "}

                        >
                          {match.jobRequirementId &&
                            match.jobRequirementId}
                          {/* {match.Candidate.candidateId} */}
                        </Link>
                      </div>
                      <br />
                      {/* <div className="mt-2 d-inline-block font-weight-medium font-size-18px color-dark-gray " >
                        SourcedBy : {match.Candidate.preferredLocation === null
                          ? match.Candidate.Addresses[0]?.city
                          : match.Candidate.preferredLocation}
                      </div> */}
                      <div className="mt-2 d-flex d-flex-row justify-content-between">
                        <div className="d-inline-block font-weight-medium font-size-18px color-dark-gray" >
                          Interview Type : {match.JobRequirement.interviewType}
                        </div>
                        {/* <div className="d-flex p-1 matchingJob-working-type-cssText"
                        >
                          <span className="font-weight-medium colored-Forest-green font-size-16px" >
                             {match.workType &&
                              toTitleCase(match.workType.replace(/_/g, " "))} 
                          </span>
                        </div> */}
                      </div>
                      <div className="mt-2 d-flex d-flex-row justify-content-between">
                        <div className="d-inline-block font-weight-medium font-size-18px color-dark-gray" >
                          Location : {match.JobRequirement.location}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-7 col-sm-9">        <div className="mt-2 d-flex d-flex-row justify-content-between">
                          <div className="d-inline-block font-weight-medium font-size-18px color-dark-gray" >
                            Status : <font
                              className={"matchigjobdata ml-3 font-weight-normal font-family-roboto font-size-09rem text-capitalize"}
                              style={{
                                color: getStatusColor(match.status),
                              }}
                            >
                              {match.status
                              }                        </font>
                          </div>
                        </div></div>
                        <div className="col-5 col-sm-3">
                          {match.applied && (
                            <font
                              className={"matchigjobdata border-radius-05rem font-family-roboto font-size-09rem font-weight-normal application-content-applied-btn"}

                            >
                              Applied
                            </font>

                          )}
                        </div>
                      </div>
                      {/* <div className="mb-2 d-inline-block font-weight-medium font-size-18px color-dark-gray" >
                        Experience : {match.Candidate.CandidateExperiences.length !== 0 ?
                          match.Candidate.CandidateExperiences[0]
                            .totalYearOfExperience
                          : "0"} Year
                      </div> */}

                      <div className="row">
                        <div className="mb-2 ml-3 d-inline-block font-weight-medium font-size-18px color-dark-gray" >
                          Rate : ${match.JobRequirement.rate}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-5">
                          <div className=" d-flex d-flex-row">
                            <div className="d-flex align-items-center justify-content-center font-weight-medium color-dark-gray font-size-18px ">
                              Matching
                            </div>
                            <div className="flex p-2">
                              <div className="matchingJob-progressbar-width" style={{ width: '03rem' }}>
                                <CircularProgressbar
                                  value={match.matchPercentage}
                                  text={`${match.matchPercentage}%`}
                                  strokeWidth={11}
                                  // text={`100$`}
                                  styles={buildStyles({
                                    strokeWidth: 15,

                                    textColor: "#000",
                                    pathColor: "#5B4FB7",
                                    trailColor: "#f9f9f9",

                                  })}
                                  className="thick-circle"
                                />

                              </div>
                            </div>

                          </div>

                        </div>
                        <div className="p-2 py-3 col-7">
                          {/* <div className="matchingJob-progressbar-width" style={{ width: '03rem' }}> */}

                          {match.JobRequirement.domain}
                          {/* </div> */}
                          <div className="text-secondary">
                            <font
                              className="colored-lavender-gray font-size-08rem font-weight-normal"
                            >
                              {match.JobRequirement.employementType}
                            </font>
                          </div>
                        </div>
                        {/* <div className="col-md-5">
                          {/* {cardName !== "archive" && ( 

                          <div className="d-flex d-flex-row">
                            <div className="d-flex justify-content-center align-items-center font-weight-medium color-dark-gray font-size-20px" >
                              Shortlisted
                            </div>
                            <div className="flex p-2">
                              <div className="matchingJob-progressbar-width-two">
                                <CircularProgressbar
                                  value={match.shortlistedCount ?? 0}
                                  text={`${match.shortlistedCount ?? 0}`}
                                  strokeWidth={11}
                                  // text={`100$`}
                                  styles={buildStyles({
                                    strokeWidth: '15',

                                    textColor: "#000",
                                    pathColor: "#5B4FB7",
                                    trailColor: "#f9f9f9",
                                  })}
                                  className="thick-circle"
                                />
                              </div>
                            </div>

                          </div>
                        </div> */}


                        {/* <div className="col-md-4">
                          <div className="d-flex d-flex-row">
                            <div className="d-flex align-items-center justify-content-center font-weight-medium color-dark-gray font-size-20px"
                            >
                              offered
                            </div>
                            <div className="flex p-2">
                              <div className="matchingJob-progressbar-width-Three">
                                <CircularProgressbar
                                  value={0}
                                  strokeWidth={11}
                                  // text={`${match.matchPercentage}%`}
                                  text={`0`}
                                  styles={buildStyles({
                                    strokeWidth: 15,

                                    textColor: "#000",
                                    pathColor: "#5B4FB7",
                                    trailColor: "#f9f9f9",
                                  })}
                                  className="thick-circle"
                                />
                              </div>
                            </div>
                          </div>
                        </div> */}

                      </div>
                    </div>
                  </div>
                ))}
              </div>
              }

              {isDesktopOrLaptop && match.map((match, index) => (
                <div
                  key={index}
                  className={"row pl-4 background-colored-primary-white"}
                  style={{
                    borderTop: index === 0 ? '1px solid #DEDEDE' : 'none',
                    borderBottomLeftRadius: index === match.length - 1 ? '0.5rem' : 'none',
                    borderBottomRightRadius: index === match.length - 1 ? '0.5rem' : 'none',
                  }}
                >
                  <div className={"col py-3 box"}>
                    {/*heading box data*/}
                    <div className={"row align-items-center pl-4"}>
                      <div className={"col-1 pl-0"}>
                        <Link
                          to={`/candidate/jobapply/${match.uuid}/Applied`}
                          className={"matchingjobdatalink "}
                        >
                          {match.jobRequirementId && match.jobRequirementId}
                        </Link>
                        <br />
                        <font className={"matchingjobdata"}>
                          {new Date(
                            match.JobRequirement.createdAt
                          ).toLocaleDateString("en-US", option)}
                        </font>
                      </div>
                      <div className={"col-md-2 pl-3 pr-0 "}>
                        <div
                          className="application-job-title mr-5 ml-4  "
                        >
                          <Link
                            to={`/candidate/jobapply/${match.uuid}/Applied`}
                            className={"matchingjobdatalink "}
                          >
                            {match.JobRequirement.jobTitle}
                          </Link>
                        </div>
                      </div>
                      <div className="col-2">
                        <div className={"row"}>
                          <div className="col-sm-3 col-12 application-match-percentage-margin" >
                            {/* <div
                              className={"badge py-2  "}
                              style={{

                                backgroundColor: '#F6F4FF',
                                fontWeight: '600',
                                fontSize: '0.8rem',
                                color: 'rgb(126, 115, 181)',

                              }}
                            > */}
                            <div className="application-match-percentage-circular-progressbar-width">
                              <CircularProgressbar
                                value={match.matchPercentage}
                                text={`${match.matchPercentage}%`}
                                // style={{ width: '200%' }}
                                styles={buildStyles({
                                  textColor: "rgb(126, 115, 181)",
                                  pathColor: "#5B4FB7",
                                  trailColor: "#f9f9f9",
                                })}
                              />
                            </div>

                            {/* {match.matchPercentage} % */}
                            {/* </div> */}
                          </div>
                          <div className="col-sm-9 col-12 pl-2">
                            <div className={"row"}>
                              <div
                                className="col-md application-job-title"

                              >
                                <font
                                  className="font-size-1rem font-weight-600 colored-lavender-gray"
                                >
                                  {match.JobRequirement.domain}
                                </font>
                              </div>
                            </div>
                            <div className={"row"}>
                              <div className={"col-md"}>
                                <font
                                  className="colored-lavender-gray font-size-08rem font-weight-normal"
                                >
                                  {match.JobRequirement.employementType}
                                </font>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-1">
                        <font className={"matchingjobdata"}>Client</font>
                      </div>
                      <div className={"col-1 pl-3"}>
                        <font className={"matchingjobdata"}>
                          ${match.JobRequirement.rate}
                        </font>
                      </div>
                      <div className={"col-1"}>
                        <font className={"matchingjobdata"}>
                          {match.JobRequirement.interviewType}
                        </font>
                      </div>

                      <div className={"col-2 ml-5 d-flex align-items-center justify-content-center"}>
                        {match.applied && (
                          <font
                            className={"matchigjobdata border-radius-05rem font-family-roboto font-size-09rem font-weight-normal application-content-applied-btn"}

                          >
                            Applied
                          </font>

                        )}
                        {match.status &&
                          <font
                            className={"matchigjobdata ml-3 font-weight-normal font-family-roboto font-size-09rem text-capitalize"}
                            style={{
                              color: getStatusColor(match.status),
                            }}
                          >
                            {match.status
                            }                    </font>
                        }
                      </div>
                      <div className="col-1 pl-0">
                        <div
                          className="application-job-title ml-3" >
                          <font className={"matchingjobdata"}>
                            {match.JobRequirement.location}
                          </font>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="d-flex justify-content-center pt-5 pb-5">
              No Data Found
            </div>
          )}
        </>
      )
      }
    </>
  );
};

export { Data, clearFilter };
