import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { clearFilter } from "../common/clearFilter";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import { useMediaQuery } from 'react-responsive';
import { Puff } from 'react-loader-spinner'


// const currentDate = new Date();
const option = { month: 'short', day: 'numeric', year: 'numeric' };

const Data = (props) => {
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1150px)' });
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1151px)' });
    function getStatusColor(status) {
        switch (status) {
            case 'On Hold':
                return '#FFDB00';
            case 'Scheduled':
                return '#06D001';
            case 'Offered':
                return '#FF8F00';
            case 'Rejected':
                return '#FF0000';
            default:
                return ''; // or a default color if needed
        }
    }
    const [loading, setLoading] = useState(false);


    const [match, setMatch] = useState([]);
    useEffect(() => {
        try {
            setLoading(true);
            if (props.item.length !== 0) {
                setMatch(props.item);
                setLoading(false);
            }
            if (props.record === 0) {
                setLoading(false);
            }
        } catch { }
    }, [props.item]);

    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
    };
    // return matching job table data group which generated by data variable
    return (
        <>
            {loading ? (
                <div className="loader">
                    <div className="svg-wrapper">
                        <Puff
                            height="80"
                            width="80"
                            color="#3D007A"
                            ariaLabel="puff-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                        />
                    </div>
                </div>
            ) : (
                <>
                    {props.record !== 0 ? (
                        <>
                            {isTabletOrMobile && <div className="row">
                                {match.map((match) => (
                                    <div className="col-md-6" key={match.uuid && match.uuid}>
                                        <div className="matchingJobCard p-3 mt-3">
                                            <div className="mb-0 d-flex flex-row justify-content-between">
                                                <div className="inline-block font-weight-bold font-size-18px colored-Royal-purple">
                                                    {match.RemoteJob && match.RemoteJob.jobTitle}
                                                </div>
                                                <div className="d-flex">
                                                    <div className="font-weight-medium colored-Steel-gray matchingjob-cretaedAt-width font-size-16px" >
                                                        {new Date(match.RemoteJob.postedDate).toLocaleDateString(
                                                            "en-US",
                                                            option
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="mt-2 d-flex d-flex-row justify-content-between">
                                                <div className="d-inline-block font-weight-medium font-size-16px color-dark-gray" >
                                                    Tech Stack : {match.RemoteJob.techStack}
                                                </div>
                                            </div>
                                            <div className="mt-2 d-flex d-flex-row justify-content-between">
                                                <a href={match.RemoteJob.jobLink} target="_blank">
                                                    <font
                                                        className={"matchigjobdata border-radius-6px font-family-roboto font-size-09rem font-weight-normal application-content-applied-btn"}
                                                    >
                                                        Apply
                                                    </font>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            }

                            {isDesktopOrLaptop && match.map((match, index) => (
                                <div
                                    key={index}
                                    className={"row pl-4 background-colored-primary-white"}
                                    style={{
                                        borderTop: index === 0 ? '1px solid #DEDEDE' : 'none',
                                        borderBottomLeftRadius: index === match.length - 1 ? '0.5rem' : 'none',
                                        borderBottomRightRadius: index === match.length - 1 ? '0.5rem' : 'none',
                                    }}
                                >
                                    <div className={"col py-3 box"}>
                                        {/*heading box data*/}
                                        <div className={"row align-items-center pl-4"}>
                                            <div className={"col-3 pl-0"}>
                                                <font className={"matchingjobdata"}>
                                                    {match.RemoteJob && match.RemoteJob.jobTitle}
                                                </font>
                                            </div>
                                            <div className="col-3">
                                                <font className={"matchingjobdata"}>
                                                    {new Date(
                                                        match.RemoteJob.postedDate
                                                    ).toLocaleDateString("en-US", option)}
                                                </font>
                                            </div>
                                            <div className={"col-4"}>
                                                <font className={"matchingjobdata"}>
                                                    ${match.RemoteJob.techStack}
                                                </font>
                                            </div>
                                            <div className={"col-2"}>
                                                <a href={match.RemoteJob.jobLink} target="_blank">
                                                    <font
                                                        className={"matchigjobdata border-radius-6px font-family-roboto font-size-09rem font-weight-normal application-content-applied-btn"}
                                                    >
                                                        Apply
                                                    </font>
                                                </a>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : (
                        <div className="d-flex justify-content-center pt-5 pb-5">
                            No Data Found
                        </div>
                    )}
                </>
            )
            }
        </>
    );
};

export { Data, clearFilter };
