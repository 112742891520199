import { REMOTEJOBS } from "../ENVIRONMENT";

async function getRemoteJobList(token, id) {
  return await fetch(REMOTEJOBS, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${
        token ? token : sessionStorage.getItem("token")
      }`,
    },
  });
}

export { getRemoteJobList };
