import React, { useState, useEffect, useRef } from "react";
import { clearFilter } from "../common/clearFilter";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import 'reactjs-popup/dist/index.css';
import { toTitleCase } from "../common/convertToTitleCase";
import { clientRedirect } from "./clientRedirect";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { clientArchiveRedirect } from "./clientArchiveRedirect";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
import { useMediaQuery } from 'react-responsive';
import { Puff } from 'react-loader-spinner'



const Data = (props) => {
  const [loading, setLoading] = useState();
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();
  let cardName = query.get("title");
  const [match, setMatch] = useState([]);
  const ref = useRef();

  useEffect(() => {
    try {
      setLoading(true);
      if (props.item.length !== 0) {
        setMatch(props.item);
        setLoading(false);
      }
      if (props.record === 0) {
        setLoading(false);
      }
    } catch { }
  }, [props.item]);

  const options = {
    year: "numeric",
    month: "short",
    day: "numeric",
  };
  const dispatch = useDispatch();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 900px)' });
  // const handleImageClick = () => {
  //   setIsShifted(!isShifted);
  // }
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 901px)' });
  let CisGridView;
  if (isTabletOrMobile) {
    CisGridView = true;
  } else if (isDesktopOrLaptop) {
    CisGridView = props.isGridView;
  }
  // console.log(match)
  return (
    // return matching job table data group which generated by data variable
    <>
      {loading ? (
        // <div
        //   className="row h-100 background-colored-Pale-gray matchingJob-loader-container"
        // >
        //   <div className="col-sm-12 my-auto">
        //     <img
        //       src={"/icon/Gif/loader.gif"}
        //       className={"mx-auto d-block"}
        //       alt={"loader"}
        //     />
        //   </div>
        // </div>
        <div className="loader">
          <div className="svg-wrapper">    <Puff
            height="80"
            width="80"
            color="#3D007A"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          /></div>

        </div>
      ) : (
        <React.Fragment>
          {CisGridView ? (
            props.record !== 0 ? (
              <div className="row">
                {match.map((match) => (

                  <div className="col-md-6" key={match.uuid && match.uuid.split("-").pop()}>
                    <Link
                      className="matching-jobcards-link"
                      to={`/client/jobapply/${match.uuid}`}
                    >
                      <div className="matchingJobCard p-3 mt-3">
                        <div className="mb-0 d-flex flex-row justify-content-between">
                          <div className="inline-block font-weight-medium ">
                            {cardName === "archive" ? (
                              <font
                                // <font
                                //  to={`/client/filteredapplications?id=${match.uuid}`}
                                className={"matchingjobdata font-size-11rem color-dark-gray font-weight-600"}
                              // onClick={() => clientArchiveRedirect(dispatch)}
                              // style={{ color: '#1E1E1E', fontSize: '1.1rem', fontWeight: '600' }}
                              >
                                {match.jobTitle}
                              </font>
                            ) : (
                              <font
                                // to={`/client/filteredapplications?id=${match.uuid}`}
                                className={"matchingjobdata font-size-11rem color-dark-gray font-weight-600 "}
                              // style={{ color: '#1E1E1E', fontSize: '1.1rem', fontWeight: '600' }}

                              // onClick={() => clientRedirect(dispatch)}
                              >
                                {match.jobTitle}
                              </font>
                            )}
                          </div>
                          <div className="d-flex">
                            {/* <div className="font-weight-medium colored-Steel-gray matchingjob-cretaedAt-width font-size-18px" >
                            {new Date(match.createdAt).toLocaleDateString(
                              "en-US",
                              options
                            )}
                          </div> */}
                            <div className="d-flex p-1 matchingJob-working-type-cssText"
                            >
                              <span className="font-weight-medium colored-Forest-green font-size-08 "
                              // style={{ fontSize: '0.8rem' }}>
                              >
                                {match.workType &&
                                  toTitleCase(match.workType.replace(/_/g, " "))}
                              </span>
                            </div>
                            {cardName === "archive" ? (
                              <div className="PopupOne">
                                <Popup
                                  ref={ref}
                                  trigger={
                                    <i
                                      className="fa fa-ellipsis-h pl-3 font-size-16px cursor-pointer colored-Vibrant-orange"
                                      aria-hidden="true"

                                    />

                                  }
                                  className="PopUpContainerThird"
                                  id="PopupOne"
                                  position="bottom right"
                                  contentStyle={{
                                    width: '165px',
                                    height: '147px',
                                    background: '#EAEAEA',
                                    borderRadius: '10px',
                                    marginleft: '9px',
                                    pointerEvents: 'auto',
                                    top: '135.955px !important',
                                    left: '600.955px !important',

                                  }}

                                >
                                  <label
                                    className="popuptext"
                                    onClick={() => {
                                      props.handleDuplicate(match.uuid);
                                      ref.current.close();

                                    }}
                                    tabIndex="-1"
                                  >
                                    Duplicate
                                  </label>
                                  <label
                                    type={"button"}
                                    onClick={() => {
                                      ref.current.close();
                                      props.handleDownload(match.uuid);
                                    }}
                                    className="popuptext"
                                    tabIndex="-1"
                                  >
                                    Download Response
                                  </label>
                                </Popup>
                              </div>
                            ) : (
                              <Popup
                                ref={ref}
                                trigger={
                                  <i
                                    className="fa fa-ellipsis-h pl-3  font-size-16px cursor-pointer colored-Vibrant-orange"
                                    aria-hidden="true"

                                  />
                                }
                                className="PopUpContainerSecond"
                                position="bottom right"
                                contentStyle={{

                                  width: '165px',
                                  height: '147px',
                                  background: '#EAEAEA',
                                  borderRadius: '10px',
                                  marginleft: '9px',
                                  pointerEvents: 'auto',
                                  top: '135.955px !important',
                                  left: '600.955px !important',
                                }}
                              >
                                <Link to={`/client/postjobpreview/${match.uuid}`}>
                                  <label className="popuptext" tabIndex="-1">Edit</label>
                                </Link>
                                <br />
                                <label
                                  type={"button"}
                                  onClick={(e) => {
                                    ref.current.close();
                                    props.handleDelete(match.uuid);
                                  }}
                                  className="popuptext"
                                  tabIndex="-1"
                                >
                                  Archive
                                </label>
                                <br />
                                <label
                                  className="popuptext"
                                  onClick={() => {
                                    props.handleDuplicate(match.uuid);
                                    ref.current.close();
                                  }}
                                  tabIndex="-1"
                                >
                                  Duplicate
                                </label>
                                <label
                                  type={"button"}
                                  onClick={() => {
                                    ref.current.close();
                                    props.handleDownload(match.uuid);
                                  }}
                                  className="popuptext"
                                  tabIndex="-1"
                                >
                                  Download Response
                                </label>
                              </Popup>
                            )}
                            {/* <i class="fa fa-ellipsis-h pl-3" aria-hidden="true" aria-describedby="popup-8"
                          style={{ fontSize: '16px', color: '#F24E1E', cursor: 'pointer' }} ></i> */}
                          </div>
                          <div
                            className={`modal fade ${props.archiveModal ? "show" : ""
                              }`}
                            id="deactivateprofile"
                            data-backdrop={"static"}
                            style={{
                              display: `${props.archiveModal ? "block" : "none"}`,
                            }}>
                            <div>
                              <div
                                className="modal-dialog matchingjob-modal-border"

                              >
                                <div className="modal-content">
                                  <div className="modal-title pr-3 pt-3">
                                    <button
                                      type="button"
                                      className="close"
                                      data-dismiss="modal"
                                      onClick={props.hideModal}
                                    >
                                      &times;
                                    </button>
                                  </div>
                                  <div className="modal-body pl-5 ml-3 matchingjobdata">
                                    {props.archiveErrmsg} <br />
                                    you want to archive the requirement
                                  </div>
                                  <div className="row pt-3 pb-4 pl-5 ml-5">
                                    <div className="col-md-5">
                                      <button
                                        type="button"
                                        className="btn btn-success py-1"
                                        onClick={() =>
                                          props.handleAssociatedRequirement(
                                            props.archiveId
                                          )
                                        }
                                        data-dismiss="modal"
                                      >
                                        Yes
                                      </button>
                                    </div>
                                    <div className="col-md-3">
                                      <button
                                        type="button"
                                        className="btn btn-danger py-1"
                                        data-dismiss="modal"
                                        onClick={props.hideModal}
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-0 mb-2 d-inline-block font-weight-medium font-size-18px color-dark-gray" >
                          <font
                            // to={`/client/jobapply/${match.uuid}`}
                            className={"matchingjobdata  font-size-09rem font-weight-600 colored-neutral-gray"}
                          // style={{ color: '#989898', fontSize: '0.9rem', fontWeight: '600' }}
                          >
                            {match.ClientRequirementId &&
                              match.ClientRequirementId}
                          </font>
                        </div>
                        <br />
                        <div className="mt-0 mb-2 d-inline-block font-weight-medium font-size-18px color-dark-gray " >
                          {/* No of Positions : 4 */}
                          {cardName === null && (
                            // <Link
                            //   to={`/client/filteredapplications?id=${match.uuid}`}
                            //   className={"matchingjobdatalink "}
                            //   onClick={() => clientRedirect(dispatch)}
                            //   style={{ fontSize: '0.9rem' }}

                            // >
                            <div className="color-dark-gray font-size-09rem "> No Of Position: {match.numberOfPosition}</div>
                            // </Link>
                          )}
                          {cardName === "total" && (
                            // <Link
                            //   to={`/client/filteredapplications?id=${match.uuid}`}
                            //   className={"matchingjobdatalink "}
                            //   onClick={() => clientRedirect(dispatch)}
                            //   style={{ fontSize: '0.9rem' }}

                            // >
                            <div className="color-dark-gray font-size-09rem "> No Of Position: {match.numberOfPosition}</div>
                            // </Link>
                          )}
                          {cardName === "archive" && (
                            // <Link
                            //   to={`/client/filteredapplications?id=${match.uuid}`}
                            //   className={"matchingjobdatalink "}
                            //   onClick={() => clientArchiveRedirect(dispatch)}
                            //   style={{ fontSize: '0.9rem' }}

                            // >
                            <div className="color-dark-gray font-size-09rem "> No Of Position: {match.numberOfPosition}</div>
                            // </Link>
                          )}
                          {cardName === "active" && (
                            // <Link
                            //   to={`/client/filteredapplications?id=${match.uuid}`}
                            //   className={"matchingjobdatalink "}
                            //   onClick={() => clientRedirect(dispatch)}
                            //   style={{ fontSize: '0.9rem' }}

                            // >
                            <div className="color-dark-gray font-size-09rem "> No Of Position: {match.numberOfPosition}</div>
                            // </Link>
                          )}
                          {cardName === "scheduled" && (
                            // <Link
                            //   to={`/client/filteredapplications?id=${match.uuid}&card=scheduled`}
                            //   className={"matchingjobdatalink "}
                            //   onClick={() => clientRedirect(dispatch)}
                            //   style={{ fontSize: '0.9rem' }}

                            // >
                            <div className="color-dark-gray font-size-09rem ">Scheduled interviews :{match.scheduledCount}</div>
                            // </Link>
                          )}
                          {cardName === "shortlisted" && (
                            // <Link
                            //   to={`/client/filteredapplications?id=${match.uuid}&card=shortlisted`}
                            //   className={"matchingjobdatalink "}
                            //   onClick={() => clientRedirect(dispatch)}
                            //   style={{ fontSize: '0.9rem' }}

                            // >
                            <div className="color-dark-gray font-size-09rem "> Shortlisted Candidates: {match.shortlistedCount}</div>
                            // </Link>
                          )}
                          {cardName === "waiting" && (
                            <label className={"matchingjobdatalink font-size-09rem color-dark-gray font-size-09rem"} >


                              Waiting Candidates
                            </label>
                          )}
                          {cardName === "offer" && (
                            // <Link
                            //   to={`/client/filteredapplications?id=${match.uuid}&card=offer`}
                            //   className={"matchingjobdatalink "}
                            //   style={{ fontSize: '0.9rem' }}

                            // >
                            <div className="color-dark-gray font-size-09rem ">    Offer Roleout {match.offerCount}</div>
                            // </Link>
                          )}
                        </div>
                        <div className="mt-0 mb-2 d-flex d-flex-row justify-content-between">
                          <div className="d-inline-block font-weight-medium color-dark-gray color-dark-gray font-size-09rem" >
                            Location : {match.location}
                          </div>
                          {/* <div className="d-flex p-1 matchingJob-working-type-cssText"
                        >
                          <span className="font-weight-medium colored-Forest-green font-size-16px" >
                            {match.workType &&
                              toTitleCase(match.workType.replace(/_/g, " "))}
                          </span>
                        </div> */}
                        </div>
                        <div className="mb-2 d-inline-block font-weight-medium  color-dark-gray color-dark-gray font-size-09rem " >
                          Duration  {match.minDuration} Month
                        </div>
                        <div className="row">
                          {/* <div className="col-lg-3 col-12">
                          <div className=" d-flex d-flex-row">
                            <div className="  d-flex align-items-center justify-content-center font-weight-medium color-dark-gray font-size-20px ">
                              Rate
                            </div>
                            <div className="
                            flex p-2">
                              <div className="matchingJob-progressbar-width">
                                <CircularProgressbar
                                  value={match.rate}
                                  text={`$${match.rate}`}
                                  strokeWidth={11}
                                  // text={`100$`}
                                  styles={buildStyles({
                                    strokeWidth: 15,

                                    textColor: "#000",
                                    pathColor: "#5B4FB7",
                                    trailColor: "#f9f9f9",

                                  })}
                                  className="thick-circle"
                                />

                              </div>
                            </div>
                          </div>
                        </div> */}

                          <div className="d-inline-block mx-2" style={{ borderRadius: '9px ', fontSize: '0.9rem' }}>
                            {/* {cardName !== "archive" && ( */}
                            {/* <div className="d-flex d-flex-row align-items"> */}
                            <div className="font-weight-normal colored-primary-white  font-size-09rem background-colored-Lavender-slate border-radius-07px py-0 px-2"
                            >
                              offered :<span>{match.offerCount ?? 0}</span>
                              {/* </div> */}
                              {/* <div className="flex p-2">
                              <div className="matchingJob-progressbar-width-two">
                                <CircularProgressbar
                                  value={match.shortlistedCount ?? 0}
                                  text={`${match.shortlistedCount ?? 0}`}
                                  strokeWidth={11}
                                  // text={`100$`}
                                  styles={buildStyles({
                                    strokeWidth: '15',

                                    textColor: "#000",
                                    pathColor: "#5B4FB7",
                                    trailColor: "#f9f9f9",
                                  })}
                                  className="thick-circle"
                                />
                              </div>
                            </div> */}

                            </div>
                            {/* )} */}
                          </div>


                          <div className="d-inline-block">
                            {/* <div className="d-flex d-flex-row"> */}

                            <div className="d-flex justify-content-center align-items-center font-weight-normal  font-size-09rem py-0 px-2  font-size-09rem background-colored-Lavender-slate colored-primary-white border-radius-07px"
                            //  style={{ fontSize: '0.9rem', color: 'white', backgroundColor: '#7E73B5', borderRadius: '7px' }}>
                            >
                              Shortlisted :<span>{match.shortlistedCount ?? 0}</span>
                            </div>
                            {/* <div className="flex p-2">
                              <div className="matchingJob-progressbar-width-Three">
                                <CircularProgressbar
                                  value={0}
                                  strokeWidth={11}
                                  // text={`${match.matchPercentage}%`}
                                  text={`0`}
                                  styles={buildStyles({
                                    strokeWidth: 15,

                                    textColor: "#000",
                                    pathColor: "#5B4FB7",
                                    trailColor: "#f9f9f9",
                                  })}
                                  className="thick-circle"
                                />
                              </div>
                            </div> */}
                          </div>
                          {/* </div> */}

                        </div>
                        <hr className="boder-colored-Cloud-gray" />
                        {/* <div className="row"> */}
                        <div className="d-flex align-items-center justify-content-between color-dark-gray font-size-09rem " >
                          <div>${match.rate}</div>
                          <div className="font-weight-medium colored-Steel-gray matchingjob-cretaedAt-width font-size-09rem">
                            {new Date(match.createdAt).toLocaleDateString(
                              "en-US",
                              options
                            )}
                          </div>
                        </div>
                        {/* </div> */}

                      </div>
                    </Link>

                  </div>

                ))}

              </div >
            ) : (
              <div className={"d-flex justify-content-center pt-5 pb-5"}>
                No Data Found
              </div>
            )
          ) : (
            props.record !== 0 ? (
              <>
                {match.map((match, index) => (
                  <div
                    className={`row pl-4 background-colored-primary-white ${index === 0 ? 'matchingJob-Tabular-view-box-shadow' : ''}`}

                    key={match.uuid && match.uuid.split("-").pop()}
                  >
                    <div className={"col py-3 box"}>
                      <div className={"row  align-items-center pl-3"}>
                        <div className={"col-2 pl-4"}>
                          <Link
                            to={`/client/jobapply/${match.uuid}`}
                            className={"matchingjobdatalink "}
                          >
                            {match.ClientRequirementId &&
                              match.ClientRequirementId}
                          </Link>
                          <br />
                          <font className="matchingjobdata">
                            {new Date(match.createdAt).toLocaleDateString(
                              "en-US",
                              options
                            )}
                          </font>
                          <br />
                          <font className={"matchingjobdata"}>
                            {match.hiringType && match.hiringType.substring(0, 8)}
                          </font>
                        </div>
                        <div className={"col-md-1"}>
                          <div className="">
                            <div
                              className="matchingJob-jobTitle-css"

                            >
                              {cardName === "archive" ? (
                                <Link
                                  to={`/client/filteredapplications?id=${match.uuid}`}
                                  className={"matchingjobdatalink font-size-14px "}
                                  onClick={() => clientArchiveRedirect(dispatch)}

                                >
                                  {match.jobTitle}
                                </Link>
                              ) : (
                                <Link
                                  to={`/client/filteredapplications?id=${match.uuid}`}
                                  className={"matchingjobdatalink font-size-14px "}
                                  onClick={() => clientRedirect(dispatch)}

                                >
                                  {match.jobTitle}
                                </Link>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-1">
                          <div
                            className="row d-flex justify-content-center"

                          >
                            {cardName === null && (
                              <font
                                // to={`/client/filteredapplications?id=${match.uuid}`}
                                // className={"matchingjobdatalink "}
                                // onClick={() => clientRedirect(dispatch)}
                                className={"matchingjobdata"}

                              >
                                {match.numberOfPosition}
                              </font >
                            )}
                            {cardName === "total" && (
                              <font
                                // to={`/client/filteredapplications?id=${match.uuid}`}
                                // className={"matchingjobdatalink "}
                                // onClick={() => clientRedirect(dispatch)}
                                className={"matchingjobdata"}

                              >
                                {match.numberOfPosition}
                              </font>
                            )}
                            {cardName === "archive" && (
                              <font
                                // to={`/client/filteredapplications?id=${match.uuid}`}
                                // className={"matchingjobdatalink "}
                                // onClick={() => clientArchiveRedirect(dispatch)}
                                className={"matchingjobdata"}

                              >
                                {match.numberOfPosition}
                              </font>
                            )}
                            {cardName === "active" && (
                              <font
                                // to={`/client/filteredapplications?id=${match.uuid}`}
                                className={"matchingjobdata"}
                              // onClick={() => clientRedirect(dispatch)}
                              >
                                No Of Position {match.numberOfPosition}
                              </font>
                            )}
                            {cardName === "scheduled" && (
                              <Link
                                to={`/client/filteredapplications?id=${match.uuid}&card=scheduled`}
                                className={"matchingjobdatalink"}
                                onClick={() => clientRedirect(dispatch)}
                              >
                                Scheduled interviews {match.scheduledCount}
                              </Link>
                            )}
                            {cardName === "shortlisted" && (
                              <Link
                                to={`/client/filteredapplications?id=${match.uuid}&card=shortlisted`}
                                className={"matchingjobdatalink "}
                                onClick={() => clientRedirect(dispatch)}
                              >
                                Shortlisted Candidates {match.shortlistedCount}
                              </Link>
                            )}
                            {cardName === "waiting" && (
                              <label className={"matchingjobdatalink "}>
                                Waiting Candidates
                              </label>
                            )}
                            {cardName === "offer" && (
                              <Link
                                to={`/client/filteredapplications?id=${match.uuid}&card=offer`}
                                className={"matchingjobdatalink"}
                              >
                                Offer Roleout {match.offerCount}
                              </Link>
                            )}
                          </div>
                        </div>
                        <div className="col-2">
                          <div className={"row pl-4"}>
                            <div className={"col ml-1"}>
                              <div className={"row"}>
                                <div className={"col-md"}>
                                  <font className={"matchingjobdata"}>
                                    {match.minDuration} month
                                  </font>
                                </div>
                              </div>
                              <div className={"row"}>
                                <div
                                  className="col-md matchingJob-wordwrapAnd-wordSpace"

                                >
                                  <font className={"matchingjobdata"}>
                                    {match.location}
                                  </font>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={`col-${cardName !== "archive" ? 1 : 2} pr-5 ${cardName !== "archive" ? '' : "text-center"}`}>
                          <div
                            className="matchingJob-wordwrapAnd-wordSpace"

                          >
                            <font className={"matchingjobdata matching-job-14rem"} >
                              ${match.rate}
                            </font>
                          </div>
                        </div>
                        {cardName !== "archive" && <div className={"col-1 pl-0 pr-0"}>
                          <font className={"matchingjobdata"}>
                            {match.shortlistedCount ?? 0}
                          </font>
                        </div>
                        }
                        <div className="col-2 pl-0">
                          <font className={"matchingjobdata"}>
                            {match.workType &&
                              toTitleCase(match.workType.replace(/_/g, " "))}
                          </font>
                        </div>
                        <div className="col-1 pr-0">
                          <font className={"matchingjobdata"}>{match.offerCount ?? 0}</font>
                        </div>
                        <div className="col-1">
                          {cardName === "archive" ? (
                            <Popup
                              ref={ref}
                              trigger={
                                <i
                                  className="fa fa-ellipsis-v pl-3 text-danger cursor-pointer"
                                  aria-hidden="true"

                                />
                              }
                              className="PopUpContainerThird"
                              position="bottom right"
                              contentStyle={{
                                width: "165px",
                                height: "100px",
                                background: "#F9F9F9",
                                borderRadius: "10px",
                                marginLeft: "20px",
                              }}
                            >
                              <label
                                className="popuptext"
                                onClick={() => {
                                  props.handleDuplicate(match.uuid);
                                  ref.current.close();
                                }}
                              >
                                Duplicate
                              </label>
                              <label
                                type={"button"}
                                onClick={() => {
                                  ref.current.close();
                                  props.handleDownload(match.uuid);
                                }}
                                className="popuptext"
                              >
                                Download Response
                              </label>
                            </Popup>
                          ) : (
                            <Popup
                              ref={ref}
                              className="PopUpContainerSecondF"
                              trigger={
                                <i
                                  className="fa fa-ellipsis-v pl-3 text-danger cursor-pointer"
                                  aria-hidden="true"

                                />
                              }
                              position="bottom right"
                              contentStyle={{
                                width: "165px",
                                height: "140px",
                                background: "#F9F9F9",
                                borderRadius: "10px",
                                marginLeft: "20px",
                              }}
                            >
                              <Link to={`/client/postjobpreview/${match.uuid}`}>
                                <label className="popuptext">Edit</label>
                              </Link>
                              <br />
                              <label
                                type={"button"}
                                onClick={(e) => {
                                  ref.current.close();
                                  props.handleDelete(match.uuid);
                                }}
                                className="popuptext"
                              >
                                Archive
                              </label>
                              <br />
                              <label
                                className="popuptext"
                                onClick={() => {
                                  props.handleDuplicate(match.uuid);
                                  ref.current.close();
                                }}
                              >
                                Duplicate
                              </label>
                              <label
                                type={"button"}
                                onClick={() => {
                                  ref.current.close();
                                  props.handleDownload(match.uuid);
                                }}
                                className="popuptext"
                              >
                                Download Response
                              </label>
                            </Popup>
                          )}
                        </div>
                        <div
                          className={`modal fade ${props.archiveModal ? "show" : ""
                            }`}
                          id="deactivateprofile"
                          data-backdrop={"static"}
                          style={{
                            display: `${props.archiveModal ? "block" : "none"}`,
                          }}
                        >
                          <div
                            className="modal-dialog matchingjob-modal-border"
                          // style={{ border: "2px solid #eaeaec" }}
                          >
                            <div className="modal-content">
                              <div className="modal-title pr-3 pt-3">
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  onClick={props.hideModal}
                                >
                                  &times;
                                </button>
                              </div>
                              <div className="modal-body pl-5 ml-3 matchingjobdata">
                                {props.archiveErrmsg} <br />
                                you want to archive the requirement
                              </div>
                              <div className="row pt-3 pb-4 pl-5 ml-5">
                                <div className="col-md-5">
                                  <button
                                    type="button"
                                    className="btn btn-success py-1"
                                    onClick={() =>
                                      props.handleAssociatedRequirement(
                                        props.archiveId
                                      )
                                    }
                                    data-dismiss="modal"
                                  >
                                    Yes
                                  </button>
                                </div>
                                <div className="col-md-3">
                                  <button
                                    type="button"
                                    className="btn btn-danger py-1"
                                    data-dismiss="modal"
                                    onClick={props.hideModal}
                                  >
                                    No
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className={"d-flex justify-content-center pt-5 pb-5"}>
                No Data Found
              </div>)
          )}


        </React.Fragment >
      )}
    </>
  );
};
export { Data, clearFilter };


// <div
//   className={"row pl-4"}
//   style={{
//     boxShadow: "0px 12px 23px rgba(62,73,84,0.04)",
//     backgroundColor: "white",
//     marginTop: "1px",
//   }}
//   key={match.uuid && match.uuid.split("-").pop()}
// >
//   <div className={"col py-3 box"}>
//     <div className={"row  align-items-center pl-3"}>
//       <div className={"col-2 pl-4"}>
//         <Link
//           to={`/client/jobapply/${match.uuid}`}
//           className={"matchingjobdata text-primary"}
//         >
//           {match.ClientRequirementId &&
//             match.ClientRequirementId}
//         </Link>
//         <br />
//         <font className="matchingjobdata">
//           {new Date(match.createdAt).toLocaleDateString(
//             "en-US",
//             options
//           )}
//         </font>
//         <br />
//         <font className={"matchingjobdata"}>
//           {match.hiringType && match.hiringType.substring(0, 8)}
//         </font>
//       </div>
//       <div className={"col-md-2"}>
//         <div className="row">
//           <div
//             className="col-md"
//             style={{
//               wordWrap: "break-word",
//               whiteSpace: "pre-wrap",
//               textAlign: "center",
//             }}
//           >
//             {cardName === "archive" ? (
//               <Link
//                 to={`/client/filteredapplications?id=${match.uuid}`}
//                 className={"matchingjobdata text-primary"}
//                 onClick={() => clientArchiveRedirect(dispatch)}
//               >
//                 {match.jobTitle}
//               </Link>
//             ) : (
//               <Link
//                 to={`/client/filteredapplications?id=${match.uuid}`}
//                 className={"matchingjobdata text-primary"}
//                 onClick={() => clientRedirect(dispatch)}
//               >
//                 {match.jobTitle}
//               </Link>
//             )}
//           </div>
//         </div>
//         <div
//           className="row"
//           style={{ display: "flex", justifyContent: "center" }}
//         >
//           {cardName === null && (
//             <Link
//               to={`/client/filteredapplications?id=${match.uuid}`}
//               className={"matchingjobdata text-primary"}
//               onClick={() => clientRedirect(dispatch)}
//             >
//               No Of Position {match.numberOfPosition}
//             </Link>
//           )}
//           {cardName === "total" && (
//             <Link
//               to={`/client/filteredapplications?id=${match.uuid}`}
//               className={"matchingjobdata text-primary"}
//               onClick={() => clientRedirect(dispatch)}
//             >
//               No Of Position {match.numberOfPosition}
//             </Link>
//           )}
//           {cardName === "archive" && (
//             <Link
//               to={`/client/filteredapplications?id=${match.uuid}`}
//               className={"matchingjobdata text-primary"}
//               onClick={() => clientArchiveRedirect(dispatch)}
//             >
//               No Of Position {match.numberOfPosition}
//             </Link>
//           )}
//           {cardName === "active" && (
//             <Link
//               to={`/client/filteredapplications?id=${match.uuid}`}
//               className={"matchingjobdata text-primary"}
//               onClick={() => clientRedirect(dispatch)}
//             >
//               No Of Position {match.numberOfPosition}
//             </Link>
//           )}
//           {cardName === "scheduled" && (
//             <Link
//               to={`/client/filteredapplications?id=${match.uuid}&card=scheduled`}
//               className={"matchingjobdata text-primary"}
//               onClick={() => clientRedirect(dispatch)}
//             >
//               Scheduled interviews {match.scheduledCount}
//             </Link>
//           )}
//           {cardName === "shortlisted" && (
//             <Link
//               to={`/client/filteredapplications?id=${match.uuid}&card=shortlisted`}
//               className={"matchingjobdata text-primary"}
//               onClick={() => clientRedirect(dispatch)}
//             >
//               Shortlisted Candidates {match.shortlistedCount}
//             </Link>
//           )}
//           {cardName === "waiting" && (
//             <label className={"matchingjobdata text-primary"}>
//               Waiting Candidates
//             </label>
//           )}
//           {cardName === "offer" && (
//             <Link
//               to={`/client/filteredapplications?id=${match.uuid}&card=offer`}
//               className={"matchingjobdata text-primary"}
//             >
//               Offer Roleout {match.offerCount}
//             </Link>
//           )}
//         </div>
//       </div>
//       <div className="col-2">
//         <div className={"row pl-4"}>
//           <div className={"col ml-1"}>
//             <div className={"row"}>
//               <div className={"col-md"}>
//                 <font className={"matchingjobdata"}>
//                   {match.minDuration} month
//                 </font>
//               </div>
//             </div>
//             <div className={"row"}>
//               <div
//                 className="col-md"
//                 style={{
//                   wordWrap: "break-word",
//                   whiteSpace: "pre-wrap",
//                 }}
//               >
//                 <font className={"matchingjobdata"}>
//                   {match.location}
//                 </font>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className={"col-1 pr-5"}>
//         <div
//           style={{
//             wordWrap: "break-word",
//             whiteSpace: "pre-wrap",
//           }}
//         >
//           <font className={"matchingjobdata"}>
//             ${match.rate}
//           </font>
//         </div>
//       </div>
//       <div className={"col-1 pl-0 pr-0"}>
//         <font className={"matchingjobdata"}>
//           {match.shortlistedCount}
//         </font>
//       </div>
//       <div className="col-2 pl-0">
//         <font className={"matchingjobdata"}>
//           {match.workType &&
//             toTitleCase(match.workType.replace(/_/g, " "))}
//         </font>
//       </div>
//       <div className="col-1 pr-0">
//         <font className={"matchingjobdata"}>0</font>
//       </div>
//       <div className="col-1">
//         {cardName === "archive" ? (
//           <Popup
//             ref={ref}
//             trigger={
//               <i
//                 className="fa fa-ellipsis-v pl-3"
//                 aria-hidden="true"
//                 style={{ cursor: "pointer" }}
//               />
//             }
//             position="bottom right"
//             contentStyle={{
//               width: "165px",
//               height: "100px",
//               background: "#F9F9F9",
//               borderRadius: "10px",
//               marginLeft: "20px",
//             }}
//           >
//             <label
//               className="popuptext"
//               onClick={() => {
//                 props.handleDuplicate(match.uuid);
//                 ref.current.close();
//               }}
//             >
//               Duplicate
//             </label>
//             <label
//               type={"button"}
//               onClick={() => {
//                 ref.current.close();
//                 props.handleDownload(match.uuid);
//               }}
//               className="popuptext"
//             >
//               Download Response
//             </label>
//           </Popup>
//         ) : (
//           <Popup
//             ref={ref}
//             trigger={
//               <i
//                 className="fa fa-ellipsis-v pl-3"
//                 aria-hidden="true"
//                 style={{ cursor: "pointer" }}
//               />
//             }
//             position="bottom right"
//             contentStyle={{
//               width: "165px",
//               height: "140px",
//               background: "#F9F9F9",
//               borderRadius: "10px",
//               marginLeft: "20px",
//             }}
//           >
//             <Link to={`/client/postjobpreview/${match.uuid}`}>
//               <label className="popuptext">Edit</label>
//             </Link>
//             <br />
//             <label
//               type={"button"}
//               onClick={(e) => {
//                 ref.current.close();
//                 props.handleDelete(match.uuid);
//               }}
//               className="popuptext"
//             >
//               Archive
//             </label>
//             <br />
//             <label
//               className="popuptext"
//               onClick={() => {
//                 props.handleDuplicate(match.uuid);
//                 ref.current.close();
//               }}
//             >
//               Duplicate
//             </label>
//             <label
//               type={"button"}
//               onClick={() => {
//                 ref.current.close();
//                 props.handleDownload(match.uuid);
//               }}
//               className="popuptext"
//             >
//               Download Response
//             </label>
//           </Popup>
//         )}
//       </div>
//       <div
//         className={`modal fade ${
//           props.archiveModal ? "show" : ""
//         }`}
//         id="deactivateprofile"
//         data-backdrop={"static"}
//         style={{
//           display: `${props.archiveModal ? "block" : "none"}`,
//         }}
//       >
//         <div
//           className="modal-dialog"
//           style={{ border: "2px solid #eaeaec" }}
//         >
//           <div className="modal-content">
//             <div className="modal-title pr-3 pt-3">
//               <button
//                 type="button"
//                 className="close"
//                 data-dismiss="modal"
//                 onClick={props.hideModal}
//               >
//                 &times;
//               </button>
//             </div>
//             <div className="modal-body pl-5 ml-3 matchingjobdata">
//               {props.archiveErrmsg} <br />
//               you want to archive the requirement
//             </div>
//             <div className="row pt-3 pb-4 pl-5 ml-5">
//               <div className="col-md-5">
//                 <button
//                   type="button"
//                   className="btn btn-success py-1"
//                   onClick={() =>
//                     props.handleAssociatedRequirement(
//                       props.archiveId
//                     )
//                   }
//                   data-dismiss="modal"
//                 >
//                   Yes
//                 </button>
//               </div>
//               <div className="col-md-3">
//                 <button
//                   type="button"
//                   className="btn btn-danger py-1"
//                   data-dismiss="modal"
//                   onClick={props.hideModal}
//                 >
//                   No
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>