import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import AdminDashcard from "./adminDashcard";
import GoToTop from "../../common/GoToTop";
import DashboardCards from "../common/DashboardCards";
// import { getPermanentDataDashboard } from "../../api/candidate/permanentDataDashboard";

const DashboardView = () => {
  const token = useSelector((state) => state.token);
  
  // return dashboardview page for existing user to view their data
  const dispatch = useDispatch();
  dispatch({ type: "newuserfalse" });
  dispatch({ type: "buildresumefalse" });
  
  const data = [
    {
      name: "Total Requirements",
      progressColor: "#8743DF",
      count: 100
    },
    {
      name: "Total Resumes",
      progressColor: "#2BC155",
      count: 1
    },
    {
      name: "Application Sent",
      progressColor: "#FF9B52",
      count: 2
    },
    {
      name: "Interview Accept",
      progressColor: "#FF9B52",
      count: 100
    },
    {
      name: "Active Resumes",
      progressColor: "#3F9AE0",
      count: 1
    },
    {
      name: "Interview Scheduled",
      progressColor: "#2BC155",
      count: 2
    }
  ]


  try {
    document.getElementById("replacetitleimg").innerText = "Dashboard";
  } catch {}

  return (
    <div className={"col mx-3"}>
      <div className={"col-lg-12 pr-0"}>
        <div className={"row"}>
          <div className={"col-lg p-0"}>
            <div className={"row viewalignment pb-3"}>
              <AdminDashcard />
            </div>
          </div>
        </div>
      </div>
      <div className={"row"}>
        <DashboardCards data={data} col={"col-lg-4"} />
      </div>
      <GoToTop />
    </div>
  );
};

export default React.memo(DashboardView);
