import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

export default React.memo(function NavbarSidebar(props) {
  // return mainmenu sidebarmenu
  const state = useSelector((state) => state.btnstate);
  const dispatch = useDispatch();
  const isrecruiter = useSelector((state) => state.isrecruiter);

  const role = props.homelocation.split("/")[1];
  let matching_job_or_req_link, matching_job_or_req_txt;
  if (role === "candidate") {
    matching_job_or_req_link = "matchjobs";
    matching_job_or_req_txt = "Matching jobs";
  } else if (role === "client") {
    matching_job_or_req_link = "requirements";
    matching_job_or_req_txt = "Requirements";
  }

  const buttonChange = (event) => {
    // change button appearance when click if we click on another button it reset previous button
    if (state.btn) {
      state.btn.style.cssText = "";
      state.txt.style.cssText = "";
      state.bg.style.cssText = "";
    }
    if (event.currentTarget.previousSibling) {
      dispatch({
        type: "btnstate",
        value: {
          btn: event.currentTarget.previousElementSibling.children[0],
          txt: event.currentTarget.children[0],
          bg: event.currentTarget.parentNode,
        },
      });
      event.currentTarget.previousElementSibling.children[0].style.cssText =
        "box-shadow: 0px 0px 31px rgba(145,96,209,0.28);";
      event.currentTarget.children[0].style.cssText =
        "color:#FFFFFF !important;font-weight:600";
      event.currentTarget.parentNode.style.cssText =
        "background:linear-gradient(90deg,#564BB6,#8C79C2);border-radius:10px 0px 0px 10px ";
    } else {
      try {
        dispatch({
          type: "btnstate",
          value: {
            btn: event.target,
            txt: event.currentTarget.nextSibling.children[0],
            bg: event.currentTarget.parentNode,
          },
        });
        event.target.style.cssText =
          "box-shadow: 0px 0px 31px rgba(145,96,209,0.28);";
        event.currentTarget.nextSibling.children[0].style.cssText =
          "color:#FFFFFF !important;font-weight:600";
        event.currentTarget.parentNode.style.cssText =
          "background:linear-gradient(90deg,#564BB6,#8C79C2);border-radius:10px 0px 0px 10px";
      } catch { }
    }
  };
  const location = useLocation();
  const locationPath = location.pathname.split("/")[2];
  return (
    <div className={"col-md-1 sidebar h-100"}>
      <Link to={props.homelocation}>
        <img
          src="/icon/Authentication/DirectHire_logo.png"
          alt={"companylogo"}
          className={"pb-3 companylogo position-relative"}
          width="180px"
        />
      </Link>
      <div className={"dashbtn"}>
        <div className={"row align-items-center pl-4 py-2"} id={"dashboardTabView"}>
          <div className={"col py-2 innerhide"}>
            <Link
              to={`/${role}/dashboardview`}
              className={"border-0 dashboardbtnpadding"}
              onClick={buttonChange}
              {...(locationPath === "dashboardview"
                ? {
                  onLoad: (e) => {
                    buttonChange(e);
                  },
                }
                : {})}
            >
              <img
                src="/icon/common/dashboard 1.svg"
                alt={"dashboard"}
                className={"dashboardbtnimgpadding"}
              />
            </Link>
            <Link
              to={`/${role}/dashboardview`}
              className={
                "text-decoration-none border-0 dashboardbtnpadding hide"
              }
              onClick={buttonChange}
            >
              <font className={"dashboardfont pl-3"} onClick={buttonChange}>
                &nbsp;Dashboard
              </font>
            </Link>
          </div>
        </div>
        <div className={"row align-items-center pl-4 py-2"} id={"viewmore"}>
          <div className={"col py-2 innerhide"}>
            <Link
              to={`/${role}/${matching_job_or_req_link}`}
              className={"border-0 dashboardbtnpadding"}
              onClick={buttonChange}
              {...(locationPath === matching_job_or_req_link
                ? {
                  onLoad: (e) => {
                    buttonChange(e);
                  },
                }
                : {})}
            >
              {matching_job_or_req_txt === 'Requirements' && <img
                src="/icon/common/requirement_icon.svg"
                alt={"matchingjob"}
                className={"dashboardbtnimgpadding"}
              />}
              {matching_job_or_req_txt === 'Matching jobs' && <img
                src="/icon/common/search 1.svg"
                alt={"matchingjob"}
                className={"dashboardbtnimgpadding"}
              />}
            </Link>
            <Link
              to={`/${role}/${matching_job_or_req_link}`}
              className={
                "text-decoration-none border-0 dashboardbtnpadding hide"
              }
              onClick={buttonChange}
            >
              <font className={"dashboardfont pl-3"} onClick={buttonChange}>
                {matching_job_or_req_txt}
              </font>
            </Link>
          </div>
        </div>
        {role === 'candidate' && <div
          className={"row align-items-center pl-4 py-2"} id={"remotejobbtn"}>
          <div className={"col py-2 innerhide"}>
            <Link
              to={`/${role}/remotejobs`}
              className={"border-0 dashboardbtnpadding"}
              onClick={buttonChange}
              {...(locationPath === "remotejobs"
                ? {
                  onLoad: (e) => {
                    buttonChange(e);
                  },
                }
                : {})}
            >
              <img
                src="/icon/common/search 1.svg"
                alt={"remote jobs"}
                className={"dashboardbtnimgpadding"}
              />
            </Link>
            <Link
              to={`/${role}/remotejobs`}
              className={
                "text-decoration-none border-0 dashboardbtnpadding hide"
              }
              onClick={buttonChange}
            >
              <font className={"dashboardfont pl-3"} onClick={buttonChange}>
                Remote jobs
              </font>
            </Link>
          </div>
        </div>
        }
        <div
          className={"row align-items-center pl-4 py-2"}
          id={"applicationbtn"}
        >
          <div className={"col py-2 innerhide"}>
            <Link
              to={`/${role}/applications`}
              className={"border-0 dashboardbtnpadding"}
              onClick={buttonChange}
              {...(locationPath === "applications"
                ? {
                  onLoad: (e) => {
                    buttonChange(e);
                  },
                }
                : locationPath === "filteredapplications"
                  ? {
                    onLoad: (e) => {
                      buttonChange(e);
                    },
                  }
                  : {})}
            >
              <img
                src="/icon/common/application_icon.svg"
                alt={"application"}
                className={"dashboardbtnimgpadding"}
              />
            </Link>
            <Link
              to={`/${role}/applications`}
              className={
                "text-decoration-none border-0 dashboardbtnpadding hide"
              }
              onClick={buttonChange}
            >
              <font className={"dashboardfont pl-3"} onClick={buttonChange}>
                Applications
              </font>
            </Link>
          </div>
        </div>
        {props.homelocation.split("/")[1] === "client" ? (
          <div className={"row align-items-center pl-4 py-2"}>
            <div className={"col py-2 innerhide"}>
              <Link
                to={`/${role}/archiverequirements?title=archive`}
                className={"border-0 dashboardbtnpadding"}
                onClick={buttonChange}
                {...(locationPath === "archiverequirements"
                  ? {
                    onLoad: (e) => {
                      buttonChange(e);
                    },
                  }
                  : {})}
              >
                <img
                  src="/icon/common/archive_icon.svg"
                  alt={"message"}
                  className={"dashboardbtnimgpadding"}
                />
              </Link>
              <Link
                to={`/${role}/archiverequirements?title=archive`}
                className={
                  "text-decoration-none border-0 dashboardbtnpadding hide"
                }
                onClick={buttonChange}
              >
                <font className={"dashboardfont pl-3"} onClick={buttonChange}>
                  Archived Job
                </font>
              </Link>
            </div>
          </div>
        ) : (
          ""
        )}
        {isrecruiter ? (
          <>
            {props.homelocation.split("/")[1] === "candidate" ? (
              <div className={"row align-items-center pl-4 py-2"}>
                <div className={"col py-2 innerhide"}>
                  <Link
                    to={"/candidate/candidateListDetails"}
                    className={"border-0 dashboardbtnpadding"}
                    onClick={buttonChange}
                  >
                    <img
                      src="/icon/common/candidates.png"
                      alt={"message"}
                      className={"dashboardbtnimgpadding"}
                    />
                  </Link>
                  <Link
                    to={"/candidate/candidateListDetails"}
                    className={
                      "text-decoration-none border-0 dashboardbtnpadding hide"
                    }
                    onClick={buttonChange}
                  >
                    <font
                      className={"dashboardfont pl-3"}
                      onClick={buttonChange}
                    >
                      Candidates
                    </font>
                  </Link>
                </div>
              </div>
            ) : (
              ""
            )}
          </>
        ) : (
          ""
        )}
        <div className={"row align-items-center pl-4 py-2"}>
          <div className={"col py-2 innerhide"}>
            <Link
              to={`/${role}/message`}
              className={"border-0 dashboardbtnpadding"}
              onClick={buttonChange}
              {...(locationPath === "message"
                ? {
                  onLoad: (e) => {
                    buttonChange(e);
                  },
                }
                : {})}
            >
              <img
                src="/icon/common/chat_icon.svg"
                alt={"message"}
                className={"dashboardbtnimgpadding"}
              />
            </Link>
            <Link
              to={`/${role}/message`}
              className={
                "text-decoration-none border-0 dashboardbtnpadding hide"
              }
              onClick={buttonChange}
            >
              <font className={"dashboardfont pl-3"} onClick={buttonChange}>
                Message
              </font>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
});
