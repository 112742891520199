import React, { useState, useEffect, useRef } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { EditorState, ContentState } from "draft-js";
import GoToTop from "../../common/GoToTop";
// import { Editor } from "react-draft-wysiwyg";
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';

// import { Editor } from '@tinymce/tinymce-react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { postBuildRequirementDescription } from "../../../api/client/buildRequirementDescription";
import { useSelector } from "react-redux";
import {
  CreateSignedUrlAPI,
  uploadImage,
} from "../../../api/common/ImageUpload";
import { getRequirementInfo } from "../../../api/client/getRequirementInfo";
import { recpostBuildRequirementDescription } from "../../../api/recruiter/client/BuildRequirement/buildRequirementDescription";
import { freepostBuildRequirementDescription } from "../../../api/freelance/client/BuildRequirement/buildRequirementDescription";
import { recgetRequirementInfo } from "../../../api/recruiter/client/BuildRequirement/getRequirementInfo";
import { freegetRequirementInfo } from "../../../api/freelance/client/BuildRequirement/getRequirementInfo";
import { getrequirementParsingData } from "../../../api/client/requirementParsing";
import { freegetrequirementParsingData } from "../../../api/freelance/client/requirementParsing";
import { recgetrequirementParsingData } from "../../../api/recruiter/client/requirementParsing";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import { useHistory } from 'react-router-dom';
// import { useStepContext } from "../../../store-Jobrequirement/step-context";




const JobRequirment5 = (props) => {
  // const { currentStep, visitedSteps, updateStep } = useStepContext();


  // return jobrequirement fifth page
  const [loader, setLoader] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [information, setInformation] = useState({ uploadskill: "" });
  const [htmlContent, setHtmlContent] = useState('');

  const onEditorStateChange = (editorState) => {

    const htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    setHtmlContent(htmlContent);
    return setEditorState(editorState);
  };

  // validation for jobdescription

  const jobValidation = () => {
    var regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
    var mailregex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (regex.test(editorState.getCurrentContent().getPlainText())) {
      document.getElementById("jobdesc").innerText =
        "Phone number are not allowed";
    } else if (mailregex.test(editorState.getCurrentContent().getPlainText())) {
      document.getElementById('jobdesc').innerText = "Email is not allowed"
    }
    else {
      document.getElementById("jobdesc").innerText = "";
    }
  };

  try {
    document.getElementById("replacetitleimg").innerText = "Job Posting";
    document.getElementById("postjobid").style.display = "none";
  } catch { }

  const param = useParams();
  const id = param.id;
  const refId = param.refId;
  let descRef = useRef();

  // const steps = [
  //   { id: 1, name: 'Step 1', color: '#3D007A' },
  //   { id: 2, name: 'Step 2', color: '#4B0082' },
  //   { id: 3, name: 'Step 3', color: '#7D26CD' },
  //   { id: 4, name: 'Step 4', color: '#9932CC' },
  //   { id: 5, name: 'Step 5', color: '#BA55D3' }
  // ];
  const steps = [
    { id: 1, name: 'Step 1', color: '#3D007A', linkIndex: 6 },
    { id: 2, name: 'Step 2', color: '#4B0082', linkIndex: 1 },
    { id: 3, name: 'Step 3', color: '#7D26CD', linkIndex: 2 },
    { id: 4, name: 'Step 4', color: '#9932CC', linkIndex: 3 },
    { id: 5, name: 'Step 5', color: '#BA55D3', linkIndex: 4 }
  ];
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  //added
  const schema = yup.object().shape({
    picture: yup
      .mixed()
      .test("name", "Please select a file", (value) => {
        return value[0] && value[0].name !== "";
      })
      .test("fileSize", "File size < 5MB", (value, context) => {
        return value && value[0] && value[0].size <= 5000000;
      })
      .test("type", "Please upload a valid file", (value) => {
        return value && value[0] && (
          value[0].type === 'application/pdf' ||
          value[0].type === "application/msword" ||
          value[0].type === 'application/doc' ||
          value[0].type === 'application/ms-doc' ||
          value[0].type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );
      })
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const handelDelete = () => {
    setInformation({
      uploadskill: ""
    })
    document.getElementById("skillSet").value = null

  }
  //end

  function handleFile() {
    const file = document.getElementById("skillSet").files[0];
    const obj = {
      for: isrecruiterCandidate
        ? "RECRUITER"
        : isFreelance
          ? "FREELANCE_RECRUITER"
          : "CLIENT",
      files: [
        {
          extension: file.name.split(".")[1],
          contentType: file.type,
          fileName: file.name,
        },
      ],
    };
    CreateSignedUrlAPI(token, obj)
      .then((result) => {
        const statusCode = result.status;
        const data = result.json();
        return Promise.all([statusCode, data]).then((res) => ({
          statusCode: res[0],
          data: res[1],
        }));
      })
      .then((res) => {
        if (res.statusCode === 200) {
          const promises = res.data?.payload?.signedUrls.map((url) => {
            uploadImage(url.signedUrl, file);
            return url;
          });
          Promise.all(promises).then((data) => {
            uploadImageListHandler(data);
          });
        }
      });
  }

  function uploadImageListHandler(items) {
    setTimeout(() => {
      sessionStorage.setItem("fileUrl", items[0].fileUrl);
    }, 1000);
  }
  const history = useHistory();
  function handleClick(event) {
    event.preventDefault();
    if (descRef.current) {
      descRef.current.setAttribute("disabled", "disabled");
    }
    setLoader(true);
    let registerData = {
      skillSetKey: information.uploadskill
        ? information.uploadskill
        : sessionStorage.getItem("fileUrl"),
    };
    if (htmlContent) {
      registerData = {
        description: htmlContent.toString(),
        ...registerData,
      };
    }
    let filteredValue = {};
    Object.keys(registerData)
      .filter((key) => {
        const value = registerData[key];
        if (value || value === false) {
          return true;
        } else {
          return false;
        }
      })
      .map((key) => {
        filteredValue = { [key]: registerData[key], ...filteredValue };
      });
    const response = isrecruiterCandidate
      ? recpostBuildRequirementDescription(token, filteredValue, id)
      : isFreelance
        ? freepostBuildRequirementDescription(token, filteredValue, id)
        : postBuildRequirementDescription(token, filteredValue, id);
    response.then((res) => {
      if (res.status === 200) {
        history.push(`${props.link[5]}/${id}`);
      } else {
        descRef.current.removeAttribute("disabled");
        setLoader(false);
      }
    });
  }

  useEffect(() => {
    // updateStep(5);
    callItSelf();
  }, []);

  const callItSelf = async () => {
    if (refId) {
      const response = isrecruiterCandidate
        ? await recgetrequirementParsingData(token, refId)
        : isFreelance
          ? await freegetrequirementParsingData(token, refId)
          : await getrequirementParsingData(token, refId);
      if (response.status === 200) {
        const data = await response.json();
        const payload = data.payload.parsedData;
        if (payload.description.length !== 0) {
          setEditorState(
            EditorState.createWithContent(
              ContentState.createFromText(payload.description)
            )
          );
        }
      }
    } else {
      try {
        const response = isrecruiterCandidate
          ? await recgetRequirementInfo(token, id)
          : isFreelance
            ? await freegetRequirementInfo(token, id)
            : await getRequirementInfo(token, id);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload.requirement;
          setEditorState(
            EditorState.createWithContent(
              ContentState.createFromText(payload.description)
            )
          );
          const skillsetName = payload.skillSetKey.split("/");
          document.getElementById("filename").placeholder =
            skillsetName[skillsetName.length - 1];
        }
      } catch { }
    }
  };

  // const history = useHistory();

  // const navigateToStep = (stepNumber) => {
  //   updateStep(stepNumber);

  //   switch (stepNumber) {
  //     case 1:
  //       history.push(`${props.link[6]}/${id}`);
  //       break;
  //     case 2:
  //       history.push(`${props.link[1]}/${id}`);
  //       break;
  //     case 3:
  //       history.push(`${props.link[2]}/${id}`);
  //       break;
  //     case 4:
  //       history.push(`${props.link[3]}/${id}`);
  //       break;
  //     case 5:
  //       history.push(`${props.link[4]}/${id}`);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  const checkNextStep = (index) => {
    const step = steps.find((s) => s.id === index + 1);

    if (step && step.linkIndex !== null) {
      const link = props.link[step.linkIndex];
      if (link) {
        history.push(`${link}/${id}`);
      }
    }
  };
  return (
    <div className={"col"}>
      <form className={"form-group"} onSubmit={handleClick}>
        {/* <div className={"row pl-5"}>
          <div className={"container-fluid container1 pl-5"}>
            <ul className={"progressbar mt-5 pr-5"}>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li className={"active"}>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
              <li>
                <img
                  src="/icon/candidate/resume/step.png"
                  className={"step"}
                  alt="step"
                />
              </li>
            </ul>
          </div>
        </div> */}

        <div className={"row mx-lg-5 mx-1 pl-lg-5 mt-lg-5 resumealignment"}>
          <div className="step-container pl-lg-4">
            {steps.map((step, index) => (
              <div key={step.id}
                className={`step-arrow bordered-step-arrow ${index < 5 ? 'colored-content' : ''}`}
                style={{ backgroundColor: step.color }} onClick={() => {
                  if (step.linkIndex !== null) {
                    checkNextStep(index);
                  }
                }}>
                {step.name}
              </div>
            ))}
          </div>
        </div>

        {/* <div className={"row mx-lg-5 pl-lg-5  resumealignment "}>
          <div className={"col-lg-2 pb-3 pl-4"}>
            <Link to={`${props.link[6]}/${id}`}>
              <div
                className={"pt-2 pl-2 pr-2 jobRequirment5-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

              >
                <label
                  className="font-size-14px font-weight-500 colored-primary-black"

                >
                  Requirement
                  <br />
                  info
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to={`${props.link[1]}/${id}`}>
              <div
                className={"pl-3 pb-2 pr-3 pt-3 jobRequirment5-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

              >
                <label
                  className="font-weight-500 font-size-14px colored-primary-black"
                >
                  Skill
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to={`${props.link[2]}/${id}`}>
              <div
                className={"pt-2 pl-2 pr-2 jobRequirment5-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

              >
                <label
                  className="font-weight-500 font-size-14px colored-primary-black"
                >
                  Academics
                  <br />& Certificates
                </label>
                <br />
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <Link to={`${props.link[3]}/${id}`}>
              <div
                className={"pt-2 pl-2 pr-2 jobRequirment5-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

              >
                <label
                  className="font-weight-500 font-size-14px colored-primary-black"
                >
                  Security Clearance
                </label>
              </div>
            </Link>
          </div>
          <div className={"col-lg-2 pl-4 pb-3"}>
            <div
              className={"pt-1 pl-2 pr-2 jobRequirment5-header-design-container-style text-center border-radius-10px background-colored-primary-white"}

            >
              <div className={"row"}>
                <div className={"col-lg pb-0"}>
                  <label
                    className="font-weight-500 font-size-14px colored-primary-black"
                  >
                    Job <br />
                    Description
                  </label>
                </div>
              </div>
              <div className={"row pl-0"}>
                <div className={"col-lg"}>
                  <div
                    className="background-colored-sky-blue jobRequirment5-Requirement-info-border"

                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        <div className={"pl-1 pr-lg-5 mr-lg-5 pb-3"}>
          <div className={"row px-lg-5 pr-lg-5 mx-lg-3 pt-3"}>
            <div className={"w-100 pt-4 pl-lg-5 pl-3 pr-3 pr-lg-0 contentholder"}>
              <div className="mb-4 d-inline-block font-weight-semibold font-size-26px" >
                Job Description
              </div>
              <div className={"row"}>
                <div className={"col-md mb-2"}>
                  <label
                    className="font-weight-600 font-size-1rem colored-primary-black"

                  >
                    JobDescription
                  </label>
                </div>
              </div>
              <div className={"row"}>
                <div
                  className={"col jobRequirment5-editor-css"}

                >
                  <Editor
                    editorState={editorState}
                    // onEditorStateChange={onEditorStateChange}
                    onEditorStateChange={(event) => onEditorStateChange(event)}
                    onChange={jobValidation}

                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    localization={{ locale: "en" }}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "list",
                        "history",
                      ],
                      inline: { inDropdown: true },
                      list: { inDropdown: false },
                      link: { inDropdown: true },
                      history: { inDropdown: false },
                    }}
                  />

                  {/* <Editor
                    editorState={editorState}
                    onChange={jobValidation}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    localization={{ locale: "en" }}
                    toolbar={{
                      options: [
                        "inline",
                        "blockType",
                        "fontSize",
                        "list",
                        "history",
                      ],
                      inline: { inDropdown: true },
                      list: { inDropdown: false },
                      link: { inDropdown: true },
                      history: { inDropdown: false },
                    }}
                    onEditorStateChange={(event) => onEditorStateChange(event)}
                  /> */}
                </div>
              </div>
            </div>
            <label
              id={"jobdesc"}
              className="colored-Bright-red font-size-12px jobRequirment5-editor-label"
            // style={{ color: "red", fontSize: "12px", marginTop: "12px" }}
            ></label>
          </div>
        </div>
        <div className={"pl-1 pr-lg-5 mr-lg-5 pb-3"}>
          <div className={"row px-lg-5 pr-lg-5 mx-lg-3 pt-3"}>
            <div className={"w-100 pt-4 pl-lg-5 pl-3 pr-3 pr-lg-0 pb-4 contentholder"}>
              <div className={"row"}>
                <div className={"col-md"}>
                  <label
                    className="font-weight-500 font-size-16px colored-primary-black"

                  >
                    Skill Matrix
                  </label>
                </div>
              </div>
              <div className={"row"}>
                <div className={"col-lg-4 pt-2"}>
                  <input
                    type={"text"}
                    className={
                      "form-control resumeuploadtextbox py-3 shadow-none"
                    }
                    placeholder={information.uploadskill
                      ? information.uploadskill.name
                      : "Upload skillset"}
                    id={"filename"}
                    disabled
                  />
                </div>
                <div className={"col-lg-2 col-5 pl-3 pl-lg-3 pt-3 pr-0"}>
                  <label className={"browsebtn btn"}>
                    <input
                      type="file"
                      accept=".doc,.pdf,.docx"
                      className="jobRequirment5-fileupload-css"
                      // style={{ position: "absolute", opacity: 0 }}
                      id={"skillSet"}
                      name={"picture"}
                      {...register("picture")}
                      onChange={(event) => {
                        setInformation({
                          ...information,
                          uploadskill: event.target.files[0]
                        })
                      }}
                    />
                    <span>Browse</span>
                  </label>
                </div>
                <div className={"col-lg-2 col-5 pt-3  pt-lg-3 pl-0"}>
                  <button
                    type={"button"}
                    className={"btn uploadbtn"}
                    onClick={handleSubmit(handleFile)}
                  >
                    Upload
                  </button>
                </div>
                <div className={"col-lg-1 col-2 pr-5 pt-4 "}>
                  <label
                    className={"cancelskill"}
                    onClick={handelDelete}
                  >
                    Cancel
                  </label>
                </div>
              </div>
              <div className={"row pt-5"}>
                <div className={"col-md-6 pl-lg-5  pl-3 pt-2"}>
                  <div className={"pl-5"}>
                    <label className={"dashboardforminputtext  pl-5"}>
                    </label>
                  </div>
                </div>

                <div className={"col-md"}>
                  {errors.picture && (
                    <p className="errormsg">{errors.picture.message}</p>
                  )}
                  <label
                    className={"fileerr errormsg font-size-14px"}
                  ></label>
                  {/* end */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"row pt-4"}>
          <div className={"col pr-3"}>
            <button
              type="submit"
              className={"btn offset-sm-9 offset-4 save"}
              ref={descRef}
            >
              {loader ? (
                <i
                  className="fa fa-refresh fa-spin jobRequirment5-loader-padding"

                ></i>
              ) : (
                ""
              )}
              Snapshot
            </button>
          </div>
        </div>
      </form>
      <GoToTop />
    </div>
  );
};

export default React.memo(JobRequirment5);
