import React, { useEffect, useState } from 'react'
import { getRemoteJobList } from '../../api/candidate/remoteJobList';
import GoToTop from '../common/GoToTop';
import ReactPaginate from 'react-paginate';
import { useMediaQuery } from 'react-responsive';
import { Data } from '../../function/candidate/RemoteJob';

const RemoteJob = () => {

    try {
        document.getElementById("replacetitleimg").innerText = "Remote Jobs";
    } catch { }

    const [loading, setLoading] = useState();
    const [remoteJobsList, setRemoteJobsList] = useState([]);
    const [items, setItems] = useState(0);
    const [totalLength, setTotalLength] = useState();
    const [pageCount, setPageCount] = useState(0);
    const [page, setPage] = useState(0);
    const [record, setRecord] = useState(0);

    useEffect(() => {

        async function remoteJobList() {
            const response = await getRemoteJobList();
            if (response.status === 200) {
                let details = await response.json();

                setRemoteJobsList(details.payload)
                setRecord(details.payload.length)

                const copyDetail = [...details.payload];
                setItems(copyDetail.slice(0, 6))

                setTotalLength(details.payload.length);
                setPageCount(Math.ceil(details.payload.length / 6));
            }
        }

        remoteJobList();
    }, [])

    const handlePageClick = async (data) => {
        let currentPage = data.selected * 6;
        setPage(data.selected);

        const cropedArray = remoteJobsList.slice(currentPage, currentPage + 6)
        setItems(cropedArray);
        setLoading(false)
    }
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1151px)' });
    return (
        <div className={"col mx-lg-4 mx-1"}>

            {isDesktopOrLaptop && <div
                className={"row mt-3 align-items-center matching-job-box-shadow"}

            >
                <div className={"col py-4"}>
                    <div className={"row pl-3"}>

                        <div className={"col-3 hidebox"}>
                            <font className={"headingfont ml-5"}>Title</font>
                        </div>
                        <div className={"col-3 hidebox"}>
                            <font className={"headingfont ml-5"}>Posted Date</font>
                        </div>
                        <div className={"col-4 hidebox"}>
                            <font className={"headingfont ml-5"}>Tech Stack</font>
                        </div>
                        <div className={"col-2 hidebox"}>
                            <font className={"headingfont ml-5"}></font>
                        </div>

                    </div>
                </div>
            </div>}
            {items && items.length && <Data item={items} record={record} />}
            {/*last box of data of heading*/}
            {loading ? (
                ""
            ) : (
                <>
                    {(isDesktopOrLaptop && record !== 0) && (
                        <div
                            className={"row align-items-center matching-job-box-shadow-with-margin"}
                        >
                            <div className={"col py-2"}></div>
                        </div>
                    )}
                </>
            )}
            <div className={"col mt-5 pb-5"}>
                <div className={"row align-items-center  pb-5"}>
                    <div className={"col-md"}>
                        {totalLength > 6 && (
                            <ReactPaginate
                                previousLabel={"<< "}
                                nextLabel={" >>"}
                                breakLabel={"..."}
                                pageCount={pageCount}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination justify-content-center"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextClassName={"page-item"}
                                nextLinkClassName={"page-link"}
                                breakLinkClassName={"page-link"}
                                activeClassName={"active"}
                                forcePage={page}
                            />
                        )}
                    </div>
                </div>
            </div>
            <GoToTop />
        </div>
    );
}

export default React.memo(RemoteJob);