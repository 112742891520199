//const SOCKET_URL = "https://api-v1.dollarstaffing.com";

// const SOCKET_URL = "http://54.84.50.14:8080";
const SOCKET_URL = "https://api.dollarstaffing.erponex.com";
const SERVER_URL = SOCKET_URL + "/api/v1/";
const AUTHENTICATION_URL = SERVER_URL + "sessions/";
const LINKEDINURL = SERVER_URL + "sessions/linkedin/login";
const GETUSERINFO = SERVER_URL + "users/me";
const UPLOADIMAGE = SERVER_URL + "content/signed";
const REGISTERCANDIDATE = SERVER_URL + "candidate/profile";
const CANDIDATESKILL = SERVER_URL + "candidate/buildresume/skill";
const CANDIDATEEXPERIENCE = SERVER_URL + "candidate/buildresume/experience";
const CANDIDATEACADEMIC = SERVER_URL + "candidate/buildresume/academic";
const CANDIDATESOCIAL = SERVER_URL + "candidate/buildresume/social";
const CANDIDATESECURITY =
  SERVER_URL + "candidate/buildresume/securityclearance";
const PERMANENTDATA = SERVER_URL + "candidate/permanentdata";
const DASHBOARDJOBCOUNT = SERVER_URL + "candidate/dashboardjobcount"
const MATCHINGJOBS = SERVER_URL + "candidate/matchingjobs";
const RATECARD = SERVER_URL + "candidate/ratecard";
const CANDIDATEPERMANENTDATA = SERVER_URL + "candidate/permanentdata";
const CANDIDATEFINISH = SERVER_URL + "candidate/buildresume/finish"
const CALENDLY = SERVER_URL + "candidate/calendlyuserdetails"
const APPLYSTATUS = SERVER_URL + "candidate/matchingjobs/apply"
const APPLICATIONDETAILS = SERVER_URL + "candidate/applications"
const PRIMARYSKILL = SERVER_URL + "candidate/primaryskill";
const SECONDARYSKILL = SERVER_URL + "candidate/secondaryskill";
const CANDIDATEDOMAIN = SERVER_URL + "candidate/domain";
const CANDIDATEEDUCATION = SERVER_URL + "candidate/education";
const CANDIDATECERTIFICATE = SERVER_URL + "candidate/certificate";
const CANDIDATESHORTLIST = SERVER_URL + "candidate/shortlist";
const CANDIDATERESUMEUPLOAD = SERVER_URL + "candidate/resume/upload";
const REMOTEJOBS = SERVER_URL + "candidate/recommendedJobs";


// mine is above defined
const GETCANDIDATEUSERDETAILS = SERVER_URL + "candidate/profile";
const GETCLIENTUSERDETAILS = SERVER_URL + "client/profile";
const GETRECRUITERUSERDETAILS = SERVER_URL + "recruiter/profile";
const GETFREELANCE_RECRUITERUSERDETAILS =
  SERVER_URL + "freelance-recruiter/profile";
const CLIENTREQUIREMENTINFO =
  SERVER_URL + "client/jobrequirement/requirementinfo";
const CLIENTBUILDREQUIREMENT = SERVER_URL + "client/jobrequirement";
const GETCLIENTBUILDREQUIREMENT =
  SERVER_URL + "client/jobrequirement/getAllDetails";
const CLIENTPERMANENTDATA = SERVER_URL + "client/dashboard/permanentdata";
const CLIENTMATCHINGJOBS = SERVER_URL + "client/matchingjobs";
const CLIENTDASHBOARDREQUIREMENT = SERVER_URL + "client/dashboard/requirements";
const CLIENTDASHBOARDJOBCOUNT = SERVER_URL + "client/dashboardjobcount"
const CLIENTPRIMARY = SERVER_URL + "client/primaryskill";
const CLIENTSECONDARY = SERVER_URL + "client/secondaryskill";
const CLIENTDOMAIN = SERVER_URL + "client/getdomain";
const CLIENTACADEMIC = SERVER_URL + "client/getacademic";
const CLIENTCERTIFICATE = SERVER_URL + "client/getcertificate";
const CLIENTINTERVIEWSCHEDULE = SERVER_URL + "client/application/schedule";
const CLIENTACTIVEJOBS = SERVER_URL + "client/activerequirement";
const ARCHIVEREQUIREMENTS = SERVER_URL + "client/archivedrequirements";
const CLIENTSHORTLIST = SERVER_URL + "client/shortlist";
const CLIENTJOBUPLOAD = SERVER_URL + "client/requirement/upload";
const CLIENTJOBUPLOADEDDATA = SERVER_URL + "client/requirement/uploaded";
const ZIPCODE = SERVER_URL + "city/pincode";
const LOCATION = SERVER_URL + "city/location";

const CALENDLYAPITOKEN = "eyJraWQiOiIxY2UxZTEzNjE3ZGNmNzY2YjNjZWJjY2Y4ZGM1YmFmYThhNjVlNjg0MDIzZjdjMzJiZTgzNDliMjM4MDEzNWI0IiwidHlwIjoiUEFUIiwiYWxnIjoiRVMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dGguY2FsZW5kbHkuY29tIiwiaWF0IjoxNjU2ODU0NDM1LCJqdGkiOiJiN2U1ZTkwMy05OWEwLTQzZjQtOTE5Mi02NmFlNGNhYTJiMTYiLCJ1c2VyX3V1aWQiOiI3ZjEyOTliYi1iNTZjLTRjMTYtODY4ZS04YjIxMTdhYjk2ZTgifQ.rGr3ZvahgacLy_JyNEfzIK1_61BlCD5m9jGDVO8ODeBSkK5pBAUyJgvsuPtu27a4kyH3mJmJE9p2xRXv0NDG5A";
const MESSAGE = SERVER_URL + "messaging/conversations";
const NOTIFICATIONS = SERVER_URL + "users/me/notifications";
const COUNTNOTIFICATIONS = SERVER_URL + "users/me/notifications/count";
const READNOTIFICATIONS = SERVER_URL + "users/me/notifications/read";

const RECRUITERCANDIDATEPROFILE = SERVER_URL + "recruiter/candidate/profile";
const RECRUITERCANDIDATERATECARD = SERVER_URL + "recruiter/candidate/ratecard";
const RECRUITERBUILDSKILL = SERVER_URL + "recruiter/candidate/buildresume/skill";
const RECRUITERBUILDEXP = SERVER_URL + "recruiter/candidate/buildresume/experience";
const RECRUITERBUILDACADEMIC = SERVER_URL + "recruiter/candidate/buildresume/academic";
const RECRUITERBUILDSECURITY = SERVER_URL + "recruiter/candidate/buildresume/securityclearance";
const RECRUITERBUILDSOCIAL = SERVER_URL + "recruiter/candidate/buildresume/social";
const RECRUITERBUILDFINISH = SERVER_URL + "recruiter/candidate/buildresume/finish";
const RECRUITERDASHBOARDJOBCOUNT = SERVER_URL + "recruiter/candidate/dashboardjobcount";
const RECRUITERCANDIDATEMATCHINGJOBS = SERVER_URL + "recruiter/candidate/matchingjobs";
const RECRUITERCANDIDATEPERMANENTDATA = SERVER_URL + "recruiter/candidate/permanentdata";
const RECRUITERCANDIDATEDETAILS = SERVER_URL + "recruiter/candidate/candidatelist";
const RECRUITERCANDIDATEPRIMARY = SERVER_URL + "recruiter/candidate/primaryskill";
const RECRUITERCANDIDATESECONDARY = SERVER_URL + "recruiter/candidate/secondaryskill";
const RECRUITERCANDIDATEDOMAIN = SERVER_URL + "recruiter/candidate/domain";
const RECRUITERCANDIDATEEDUCATION = SERVER_URL + "recruiter/candidate/education";
const RECRUITERCANDIDATECERTIFICATE = SERVER_URL + "recruiter/candidate/certificate";
const RECRUITERCANDIDATESHORTLIST = SERVER_URL + "recruiter/candidate/shortlist";
const RECRUITERCANDIDATEAPPLYSTATUS = SERVER_URL + "recruiter/candidate/matchingjobs/apply";
const RECRUITERCANDIDATEAPPLICATION = SERVER_URL + "recruiter/candidate/applications";
const RECRUITERSPECIFICCANDIDATEDETAILS = SERVER_URL + "recruiter/candidate/candidatedetails";
const RECRUITERCANDIDATERESUMEUPLOAD = SERVER_URL + "recruiter/candidate/resume";

const RECRUITERCLIENTDASHBOARDJOBCOUNT = SERVER_URL + "recruiter/client/dashboardjobcount";
const RECRUITERCLIENTREQUIREMENT = SERVER_URL + "recruiter/client/dashboard/requirements";
const RECRUITERCLIENTPERMANENTDATA = SERVER_URL + "recruiter/client/dashboard/permanentdata";
const RECRUITERCLIENTACTIVEJOBS = SERVER_URL + "recruiter/client/activerequirement";
const RECRUITERARCHIVEREQUIREMENTS = SERVER_URL + "recruiter/client/archivedrequirements";
const RECRUITERBUILDREQUIREMENTINFO = SERVER_URL + "recruiter/client/jobrequirement/requirementinfo";
const RECRUITERBUILDREQUIREMENT = SERVER_URL + "recruiter/client/jobrequirement";
const GETRECRUITERBUILDREQUIREMENT = SERVER_URL + "recruiter/client/jobrequirement/getAllDetails";
const RECRUITERCLIENTMATCHINGJOBS = SERVER_URL + "recruiter/client/matchingjobs";
const RECRUITERCLIENTPRIMARY = SERVER_URL + "recruiter/client/primaryskill";
const RECRUITERCLIENTSECONDARY = SERVER_URL + "recruiter/client/secondaryskill";
const RECRUITERCLIENTDOMAIN = SERVER_URL + "recruiter/client/getdomain";
const RECRUITERCLIENTACADEMIC = SERVER_URL + "recruiter/client/getacademic";
const RECRUITERCLIENTCERTIFICATE = SERVER_URL + "recruiter/client/getcertificate";
const RECRUITERCALENDLY = SERVER_URL + "recruiter/candidate/calendlyuserdetails";
const RECRUITERCLIENTINTERVIEWSCHEDULE = SERVER_URL + "recruiter/client/application/schedule";
const RECRUITERCLIENTSHORTLIST = SERVER_URL + "recruiter/client/shortlist";
const RECRUITERJOBUPLOAD = SERVER_URL + "recruiter/client/requirement/upload";
const RECRUITERJOBUPLOADEDDATA = SERVER_URL + "recruiter/client/requirement/uploaded";

const FREELANCECANDIDATEPROFILE = SERVER_URL + "freelance-recruiter/candidate/profile";
const FREELANCECANDIDATERATECARD = SERVER_URL + "freelance-recruiter/candidate/ratecard";
const FREELANCEBUILDSKILL = SERVER_URL + "freelance-recruiter/candidate/buildresume/skill";
const FREELANCEBUILDEXP = SERVER_URL + "freelance-recruiter/candidate/buildresume/experience";
const FREELANCEBUILDACADEMIC = SERVER_URL + "freelance-recruiter/candidate/buildresume/academic";
const FREELANCEBUILDSECURITY = SERVER_URL + "freelance-recruiter/candidate/buildresume/securityclearance";
const FREELANCEBUILDSOCIAL = SERVER_URL + "freelance-recruiter/candidate/buildresume/social";
const FREELANCEBUILDFINISH = SERVER_URL + "freelance-recruiter/candidate/buildresume/finish";
const FREELANCECANDIDATEMATCHINGJOBS = SERVER_URL + "freelance-recruiter/candidate/matchingjobs"
const FREELANCECANDIDATEPERMANENTDATA = SERVER_URL + "freelance-recruiter/candidate/permanentdata";
const FREELANCEDASHBOARDJOBCOUNT = SERVER_URL + "freelance-recruiter/candidate/dashboardjobcount";
const FREELANCECANDIDATEDETAILS = SERVER_URL + "freelance-recruiter/candidate/candidatelist";
const FREELANCECANDIDATEPRIMARY = SERVER_URL + "freelance-recruiter/candidate/primaryskill";
const FREELANCECANDIDATESECONDARY = SERVER_URL + "freelance-recruiter/candidate/secondaryskill";
const FREELANCECANDIDATEDOMAIN = SERVER_URL + "freelance-recruiter/candidate/domain";
const FREELANCECANDIDATEEDUCATION = SERVER_URL + "freelance-recruiter/candidate/education";
const FREELANCECANDIDATECERTIFICATE = SERVER_URL + "freelance-recruiter/candidate/certificate";
const FREELANCECANDIDATESHORTLIST = SERVER_URL + "freelance-recruiter/candidate/shortlist";
const FREELANCECANDIDATEAPPLYSTATUS = SERVER_URL + "freelance-recruiter/candidate/matchingjobs/apply";
const FREELANCECANDIDATEAPPLICATION = SERVER_URL + "freelance-recruiter/candidate/applications";
const FREELANCESPECIFICCANDIDATEDETAILS = SERVER_URL + "freelance-recruiter/candidate/candidatedetails";
const FREELANCECANDIDATERESUMEUPLOAD = SERVER_URL + "freelance-recruiter/candidate/resume";

const FREELANCECLIENTDASHBOARDJOBCOUNT = SERVER_URL + "freelance-recruiter/client/dashboardjobcount";
const FREELANCECLIENTREQUIREMENT = SERVER_URL + "freelance-recruiter/client/dashboard/requirements";
const FREELANCECLIENTPERMANENTDATA = SERVER_URL + "freelance-recruiter/client/dashboard/permanentdata";
const FREELANCECLIENTACTIVEJOBS = SERVER_URL + "freelance-recruiter/client/activerequirement";
const FREELANCEARCHIVEREQUIREMENTS = SERVER_URL + "freelance-recruiter/client/archivedrequirements";
const FREELANCEBUILDREQUIREMENTINFO = SERVER_URL + "freelance-recruiter/client/jobrequirement/requirementinfo";
const FREELANCEBUILDREQUIREMENT = SERVER_URL + "freelance-recruiter/client/jobrequirement";
const GETFREELANCEBUILDREQUIREMENT = SERVER_URL + "freelance-recruiter/client/jobrequirement/getAllDetails";
const FREELANCECLIENTMATCHINGJOBS = SERVER_URL + "freelance-recruiter/client/matchingjobs";
const FREELANCECLIENTPRIMARY = SERVER_URL + "freelance-recruiter/client/primaryskill";
const FREELANCECLIENTSECONDARY = SERVER_URL + "freelance-recruiter/client/secondaryskill";
const FREELANCECLIENTDOMAIN = SERVER_URL + "freelance-recruiter/client/getdomain";
const FREELANCECLIENTACADEMIC = SERVER_URL + "freelance-recruiter/client/getacademic";
const FREELANCECLIENTCERTIFICATE = SERVER_URL + "freelance-recruiter/client/getcertificate";
const FREELANCECALENDLY = SERVER_URL + "freelance-recruiter/candidate/calendlyuserdetails";
const FREELANCECLIENTINTERVIEWSCHEDULE = SERVER_URL + "freelance-recruiter/client/application/schedule";
const FREELANCECLIENTSHORTLIST = SERVER_URL + "freelance-recruiter/client/shortlist";
const FREELANCEJOBUPLOAD = SERVER_URL + "freelance-recruiter/client/requirement/upload";
const FREELANCEJOBUPLOADEDDATA = SERVER_URL + "freelance-recruiter/client/requirement/uploaded";

const SUPERADMINAUTHENTICATION_URL = SERVER_URL + "adminsessions/login";
const SUPERADMINCANDIDATELIST = SERVER_URL + "superadmin/candidatelist";
const SUPERADMINCLIENTLIST = SERVER_URL + "superadmin/clientlist";
const SUPERADMINFREELANCELIST = SERVER_URL + "superadmin/freelancerecruiterlist";
const SUPERADMINRECRUITERLIST = SERVER_URL + "superadmin/recruiterlist";
const SUPERADMINDASHBOARDCOUNT = SERVER_URL + "superadmin/dashboard";
const SUPERADMINCANDIDATELOCATIONLIST = SERVER_URL + "superadmin/location";
const SUPERADMINCLIENTLOCATIONLIST = SERVER_URL + "superadmin/location";
const SUPERADMINFREELANCELOCATIONLIST = SERVER_URL + "superadmin/location";
const SUPERADMINRECRUITERLOCATIONLIST = SERVER_URL + "superadmin/location"
export {
  SERVER_URL,
  AUTHENTICATION_URL,
  LINKEDINURL,
  GETUSERINFO,
  GETCANDIDATEUSERDETAILS,
  GETCLIENTUSERDETAILS,
  GETRECRUITERUSERDETAILS,
  GETFREELANCE_RECRUITERUSERDETAILS,
  REGISTERCANDIDATE,
  UPLOADIMAGE,
  CLIENTREQUIREMENTINFO,
  CLIENTBUILDREQUIREMENT,
  CANDIDATESKILL,
  CANDIDATEEXPERIENCE,
  CANDIDATEACADEMIC,
  CANDIDATESOCIAL,
  CANDIDATESECURITY,
  CANDIDATEFINISH,
  APPLICATIONDETAILS,
  CANDIDATEDOMAIN,
  CANDIDATEEDUCATION,
  CANDIDATECERTIFICATE,
  CANDIDATESHORTLIST,
  CANDIDATERESUMEUPLOAD,
  CALENDLY,
  APPLYSTATUS,
  PERMANENTDATA,
  DASHBOARDJOBCOUNT,
  MATCHINGJOBS,
  RATECARD,
  CLIENTPERMANENTDATA,
  CANDIDATEPERMANENTDATA,
  CLIENTMATCHINGJOBS,
  GETCLIENTBUILDREQUIREMENT,
  CLIENTDASHBOARDREQUIREMENT,
  CLIENTDASHBOARDJOBCOUNT,
  CLIENTINTERVIEWSCHEDULE,
  ZIPCODE,
  LOCATION,
  MESSAGE,
  PRIMARYSKILL,
  SECONDARYSKILL,
  CLIENTPRIMARY,
  CLIENTSECONDARY,
  CLIENTDOMAIN,
  CLIENTACADEMIC,
  CLIENTCERTIFICATE,
  CALENDLYAPITOKEN,
  CLIENTACTIVEJOBS,
  ARCHIVEREQUIREMENTS,
  CLIENTSHORTLIST,
  CLIENTJOBUPLOAD,
  CLIENTJOBUPLOADEDDATA,
  RECRUITERCANDIDATEPROFILE,
  RECRUITERCANDIDATERATECARD,
  RECRUITERBUILDSKILL,
  RECRUITERBUILDEXP,
  RECRUITERBUILDACADEMIC,
  RECRUITERBUILDSECURITY,
  RECRUITERBUILDSOCIAL,
  RECRUITERBUILDFINISH,
  RECRUITERDASHBOARDJOBCOUNT,
  RECRUITERCANDIDATEMATCHINGJOBS,
  RECRUITERCANDIDATEPERMANENTDATA,
  RECRUITERCANDIDATEDETAILS,
  RECRUITERCANDIDATEPRIMARY,
  RECRUITERCANDIDATESECONDARY,
  RECRUITERCANDIDATEDOMAIN,
  RECRUITERCANDIDATEEDUCATION,
  RECRUITERCANDIDATECERTIFICATE,
  RECRUITERCANDIDATESHORTLIST,
  RECRUITERCANDIDATEAPPLYSTATUS,
  RECRUITERCANDIDATEAPPLICATION,
  RECRUITERCANDIDATERESUMEUPLOAD,
  RECRUITERSPECIFICCANDIDATEDETAILS,
  RECRUITERCLIENTDASHBOARDJOBCOUNT,
  RECRUITERCLIENTREQUIREMENT,
  RECRUITERCLIENTPERMANENTDATA,
  RECRUITERCLIENTACTIVEJOBS,
  RECRUITERARCHIVEREQUIREMENTS,
  RECRUITERBUILDREQUIREMENT,
  RECRUITERBUILDREQUIREMENTINFO,
  GETRECRUITERBUILDREQUIREMENT,
  RECRUITERCLIENTSHORTLIST,
  RECRUITERCLIENTINTERVIEWSCHEDULE,
  RECRUITERJOBUPLOAD,
  RECRUITERJOBUPLOADEDDATA,
  FREELANCECANDIDATEPROFILE,
  FREELANCECANDIDATERATECARD,
  FREELANCEBUILDSKILL,
  FREELANCEBUILDEXP,
  FREELANCEBUILDACADEMIC,
  FREELANCEBUILDSECURITY,
  FREELANCEBUILDSOCIAL,
  FREELANCEBUILDFINISH,
  FREELANCECANDIDATEMATCHINGJOBS,
  FREELANCECANDIDATEPERMANENTDATA,
  FREELANCECANDIDATEDETAILS,
  FREELANCEDASHBOARDJOBCOUNT,
  FREELANCECANDIDATEPRIMARY,
  FREELANCECANDIDATESECONDARY,
  FREELANCECANDIDATEDOMAIN,
  FREELANCECANDIDATEEDUCATION,
  FREELANCECANDIDATECERTIFICATE,
  FREELANCECANDIDATESHORTLIST,
  FREELANCECANDIDATEAPPLYSTATUS,
  FREELANCECANDIDATEAPPLICATION,
  FREELANCECANDIDATERESUMEUPLOAD,
  FREELANCECLIENTDASHBOARDJOBCOUNT,
  FREELANCECLIENTREQUIREMENT,
  FREELANCECLIENTPERMANENTDATA,
  FREELANCEARCHIVEREQUIREMENTS,
  FREELANCECLIENTACTIVEJOBS,
  FREELANCEBUILDREQUIREMENT,
  FREELANCEBUILDREQUIREMENTINFO,
  GETFREELANCEBUILDREQUIREMENT,
  RECRUITERCLIENTMATCHINGJOBS,
  FREELANCECLIENTMATCHINGJOBS,
  FREELANCEJOBUPLOAD,
  FREELANCEJOBUPLOADEDDATA,
  RECRUITERCLIENTPRIMARY,
  RECRUITERCLIENTSECONDARY,
  RECRUITERCLIENTDOMAIN,
  RECRUITERCLIENTCERTIFICATE,
  RECRUITERCLIENTACADEMIC,
  FREELANCECLIENTPRIMARY,
  FREELANCECLIENTSECONDARY,
  FREELANCECLIENTDOMAIN,
  FREELANCECLIENTACADEMIC,
  FREELANCECLIENTCERTIFICATE,
  RECRUITERCALENDLY,
  FREELANCECALENDLY,
  FREELANCECLIENTINTERVIEWSCHEDULE,
  FREELANCECLIENTSHORTLIST,
  FREELANCESPECIFICCANDIDATEDETAILS,
  SUPERADMINAUTHENTICATION_URL,
  SUPERADMINCANDIDATELIST,
  SUPERADMINCLIENTLIST,
  SUPERADMINFREELANCELIST,
  SUPERADMINRECRUITERLIST,
  NOTIFICATIONS,
  COUNTNOTIFICATIONS,
  READNOTIFICATIONS,
  SOCKET_URL,
  SUPERADMINDASHBOARDCOUNT,
  SUPERADMINCANDIDATELOCATIONLIST,
  SUPERADMINCLIENTLOCATIONLIST,
  SUPERADMINFREELANCELOCATIONLIST,
  SUPERADMINRECRUITERLOCATIONLIST,
  REMOTEJOBS

};  