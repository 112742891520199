import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { MatchingPagination } from "../../../api/candidate/matchingPagination";
import { recmatchingPagination } from "../../../api/recruiter/candidate/matchingPagination";
import { freematchingPagination } from "../../../api/freelance/candidate/matchingPagination";
// import { jobData } from "../../../function/candidate/Application";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import 'react-circular-progressbar/dist/styles.css';
const JobApplyHeader = (props) => {
  // return jobapply header data
  const paramId = useParams();
  const token = useSelector((state) => state.token);
  const isrecruiterCandidate = useSelector((state) => state.recruiterCandidate);
  const isFreelance = useSelector((state) => state.isFreelance);
  const [data, setData] = useState();

  // useEffect(() => {
  //   async function callItSelf() {
  //     try {
  //
  //       // const response = isrecruiterCandidate
  //       //   ? await recmatchingPagination(token, 1)
  //       //   : isFreelance
  //       //   ? await freematchingPagination(token, 1)
  //       //   : await MatchingPagination(token, 1);
  //       // if (response.status === 200) {
  //       //   let payload = await response.json();
  //       //   payload = payload.payload;
  //       //   if (payload) {
  //       //     payload.map((obj) => {
  //       //       if (obj.uuid === paramId.reqID) {
  //       //         setData({
  //       //           id: obj.jobRequirementId,
  //       //           employType: obj.JobRequirement.employementType,
  //       //           jobTitle: obj.JobRequirement.jobTitle,
  //       //           rate: obj.JobRequirement.rate,
  //       //           rateType: obj.JobRequirement.rateType,
  //       //           location: obj.JobRequirement.location,
  //       //           matchPercent: obj.matchPercentage,
  //       //         });
  //       //       }
  //       //       return null;
  //       //     });
  //       //   }
  //       // }
  //     } catch { }
  //   }
  //   callItSelf();
  // }, []);
  // useEffect(() => {
  //   let isMounted = true; // Flag to track if component is mounted

  //   async function callItSelf() {
  //     try {
  //       const obj = jobData.find(dataItem => dataItem.uuid === Number(reqID));
  //       if (isMounted && obj) {
  //         setData({
  //           id: obj.jobRequirementId,
  //           employType: obj.JobRequirement.employementType,
  //           jobTitle: obj.JobRequirement.jobTitle,
  //           rate: obj.JobRequirement.rate,
  //           rateType: obj.JobRequirement.rateType,
  //           location: obj.JobRequirement.location,
  //           matchPercent: obj.matchPercentage,
  //         });
  //       }
  //     } catch (error) {
  //       console.error('Error:', error);
  //     }
  //   }

  //   callItSelf();

  //   // Cleanup function
  //   return () => {
  //     isMounted = false;
  //   };
  // }, [reqID]);
  // console.log(data)

  useEffect(() => {
    async function callItSelf() {
      try {
        const response = isrecruiterCandidate
          ? await recmatchingPagination(token, 1)
          : isFreelance
            ? await freematchingPagination(token, 1)
            : await MatchingPagination(token, 1);
        if (response.status === 200) {
          let payload = await response.json();
          payload = payload.payload;
          if (payload) {
            payload.map((obj) => {
              if (obj.uuid === paramId.reqID) {
                setData({
                  id: obj.jobRequirementId,
                  employType: obj.JobRequirement.employementType,
                  jobTitle: obj.JobRequirement.jobTitle,
                  rate: obj.JobRequirement.rate,
                  rateType: obj.JobRequirement.rateType,
                  location: obj.JobRequirement.location,
                  matchPercent: obj.matchPercentage,
                });
              }
              return null;
            });
          }
        }
      } catch { }
    }
    callItSelf();
  }, []);
  // console.log(data)
  // console.log(props);
  return (
    <React.Fragment>
      <div className='ml-4'>

        <div className="row pl-3">
          <div className="col-lg-10 col-11" style={{ paddingRight: '0', paddingLeft: '0' }}>
            <div className="pt-4  colored-Lavender-slate font-size-14rem font-family-roboto font-weight-600 font-size-15rem colored-primary-black "
            // style={{
            //   fontSize: '1.5rem',
            //   color: 'black',
            //   fontWeight: 600
            // }}  
            >{data ? data.jobTitle : ''}</div>

          </div>
          <div className="col-lg-1 col-1 ml-auto mr-3 mt-3">
            {props.applyStatus && <button
              type={"button"}
              className={"btn text-info font-weight-600 mb-2 applyalignment border-radius-05rem  applied-btn-margin-css"}
            //ref={applyRef}
            >
              {props.statusName ? props.statusName : "Applied"}
            </button>

            }
            {/* <button className="btn btn-success"> Applied</button> */}
          </div>
          {/* <div className="col-lg-4 pt-4 "> */}

          {/* <label
              style={{
                fontFamily: 'Roboto',
                // fontSize: '0.7rem',
                fontWeight: '600',
                color: ' #7E73B5',

              }}
            >
              Matching
            </label> */}
          {/* </div> */}
        </div>
        <div className={"row "}>
          <div className={"col-md-8"}>
            <label
              className="colored-neutral-gray  font-family-roboto font-size-1rem font-weight-600"
            // style={{ fontWeight: '600', color: '#989898' }}
            >
              Requirement ID - <span className="colored-neutral-gray  font-family-roboto font-size-1rem font-weight-600"
              //  style={{ fontWeight: '600', color: '#989898' }}
              >{data ? data.id : ""}</span>
            </label>
          </div>
          {/* <div className={"col-md-1"}>
            <label
              className="colored-Pale-silver font-weight-500 font-size-16px"
            >
              Matching
            </label>
          </div> */}

          {/* <div className={"col-md-3 pr-0"}>
            <div
              className={"badge py-1 job-apply-header-match-percentage"} > */}
          {/*    style={{
                backgroundColor: 'rgb(246, 244, 255)',
                fontWeight: '600',
                fontSize: '0.8rem',
                color: 'rgb(126, 115, 181)',
              }}
            >
              {data ? data.matchPercent : ""}%
            </div> */}
          {/* <CircularProgressbar
                value={data ? data.matchPercent : ''}
                text={`${data ? data.matchPercent : ''}%`}
                styles={buildStyles({
                  textColor: "#000",
                  pathColor: "#5B4FB7",
                  trailColor: "#f9f9f9",
                })}
              /> */}
          {/* </div>
          </div> */}
          {/* <div className={"col-md-1 text-center"}>
            <label
              style={{ color: "#C2C2C2", fontWeight: "500", fontSize: "16px" }}
            >
              Matching
            </label>
          </div> */}
        </div>
        <div className="row">
          <div className={"col-lg-4 pl-3"}>
            <font
              className={"globalfontrule font-size-09rem colored-Deep-charcoal"}
            >
              ${data ? data.rate : ""}/{data ? data.rateType : ""}
            </font>
            <br />
          </div>
        </div>
        <div className="row">
          {/* <div className={"col-md-1 pl-4"}>
            
            </div> */}
          <div className="col-lg-12 mt-2 pl-3"  >
            {/* <CircularProgressbar
                value={match ? match.matching : ''}
                text={`${match ? match.matching : ''}%`}
                styles={buildStyles({
                  textColor: "#000",
                  pathColor: "#5B4FB7",
                  trailColor: "#f9f9f9",
                })}
              /> */}
            <label
              className="colored-Pale-silver font-weight-500 font-size-16px "

            >
              Matching
            </label>
            <span className=" mt-3 ml-3">{data ? data.matchPercent : ""}%</span>
          </div>
        </div>
        <div className={"row  pt-0 mt-0"}>
          <div className={"col-lg-8"}>
            <font
              className={"globalfontrule colored-Royal-blue font-size-09rem"}
              style={{ fontWeight: '600', color: '#000000' }}

            >
              {data ? data.employType : ""}
            </font>
            <br />
            {/* <font
              className={"globalfontrule colored-Deep-charcoal font-size-09rem"}            >
              {data ? data.jobTitle : ""}
            </font> */}
            {/* <br /> */}
            <div className={"row"}>
              <div className={"col"}>
                <font
                  className={"globalfontrule font-weight-normal font-size-12px colored-Pale-taupe"}
                >
                  {data ? data.location : ""}&nbsp;&nbsp;&nbsp;
                </font>
              </div>
            </div>
          </div>
          {/* <div className={"col-lg-4 pl-4"}>
            <font
              className={"globalfontrule font-size-09rem colored-Deep-charcoal"}
            >
              ${data ? data.rate : ""}/{data ? data.rateType : ""}
            </font>
            <br />
          </div> */}
          {/* <div className={"col-lg-4 d-flex justify-content-start align-items-center"}>
            <font
              className={"globalfontrule"}
              style={{ fontSize: "0.9rem", color: "#313131", paddingRight: '0.5rem' }}
            >
              Client Rating
            </font>
            <div className="circular-progress">
              <div className="progress-value">4.3</div>
            </div>

          </div> */}
          {/* <div className={"col-md"}>
          <Link to={"/candidate/jobapply"} className={"btn sharejobbtn"}>
            Share job
          </Link>
        </div> */}
        </div>

      </div>
    </React.Fragment>
  );
};

export default React.memo(JobApplyHeader);
