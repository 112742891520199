import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Popup from "reactjs-popup";
import { useEffect } from "react";
import { markasReadNotifications } from "../api/common/readNotification";
import { useMediaQuery } from 'react-responsive';

export default function NavbarTopbar(props) {
  // return top position navbar
  const [match, setMatch] = useState([]);
  const isrecruiter = useSelector((state) => state.isrecruiter);
  const isnewuser = useSelector((state) => state.isnewuser);
  const socket = useSelector((state) => state.socket);
  const token = useSelector((state) => state.token);
  const history = useHistory();
  const ref = useRef();
  const viewref = useRef();
  const dispatch = useDispatch();
  const [isActive, setIsActive] = useState(false);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  // const [isShifted, setIsShifted] = useState(false);
  let count = 2,
    deg = 90;

  function resetToken() {
    sessionStorage.setItem("token", "");
    sessionStorage.setItem("clientnewuser", "");
    dispatch({ type: "settoken", value: "" });

    if (socket && socket.connected) {
      socket.disconnect();
    }
  }
  function getStylesForWidth(screenWidth1) {
    // if (humbergerIcon) {
    //   return {
    //     cursor: "pointer",
    //     transform: "scale(0.8) translateX(-12rem)",
    //   };
    // }

    // if (replacetitleimg) {
    //   return {
    //     display: "inline-block",
    //     transform: "translateX(-12rem)",
    //   };
    // }
    switch (true) {
      case (screenWidth1 >= 1707):
        return [
          'cursor: pointer; transform:scale(0.8) translateX(-2rem); transition: transform 0.3s ease-in-out;', 'display: inline-block ;transform:translateX(-1rem); transition: transform 0.3s ease-in-out;',
        ];
      case (screenWidth1 >= 1517 && screenWidth1 < 1707):
        return [
          'cursor: pointer;transform:scale(0.8) translateX(0rem); transition: transform 0.3s ease-in-out;', 'display: inline-block ;transform:translateX(0rem); transition: transform 0.3s ease-in-out;',
        ];
      case (screenWidth1 >= 1366 && screenWidth1 < 1517):
        return [
          'cursor: pointer; transform:scale(0.8) translateX(2rem); transition: transform 0.3s ease-in-out;', 'display: inline-block ;transform:translateX(1.2rem); transition: transform 0.3s ease-in-out;',
        ];
      case (screenWidth1 >= 1242 && screenWidth1 < 1366):
        return [
          'cursor: pointer;transform: scale(0.8) translateX(3rem);; transition: transform 0.3s ease-in-out;', 'display:inline-block;transform: translateX(3rem); transition: transform 0.3s ease-in-out;',
        ];

      case (screenWidth1 >= 1093 && screenWidth1 < 1242):
        return [
          'cursor: pointer; transform:scale(0.8) ; transition: transform 0.3s ease-in-out;margin-left:-156%', 'display: inline-block ;; transition: transform 0.3s ease-in-out;',
        ];

      case (screenWidth1 >= 911 && screenWidth1 < 1093):
        return [
          'cursor: pointer; transform:scale(0.8) ; transition: transform 0.3s ease-in-out; margin-left:-141%', 'display: inline-block ;; transition: transform 0.3s ease-in-out;',
        ];
      case (screenWidth1 >= 780 && screenWidth1 < 911):
        return [
          'cursor: pointer; transform:scale(0.8); transition: transform 0.3s ease-in-out; margin-left: -121%;', 'display: inline-block ; transition: transform 0.3s ease-in-out;',
        ];
      case (screenWidth1 === 683):
        return [
          'cursor: pointer; transform:scale(0.8) ; transition: transform 0.3s ease-in-out;margin-left:-170%', 'display: inline-block ; transition: transform 0.3s ease-in-out;',
        ];
      case (screenWidth1 >= 684 && screenWidth1 < 780):
        return [
          'cursor: pointer; transform:scale(0.8) translateX(-17rem); transition: transform 0.3s ease-in-out;', 'display: inline-block ;transform:translateX(-13rem); transition: transform 0.3s ease-in-out;',
        ];
      case (screenWidth1 >= 546 && screenWidth1 < 683):
        return [
          'cursor: pointer; transform:scale(0.8) translateX(-5rem); transition: transform 0.3s ease-in-out;', 'display: inline-block ;transform:translateX(-4rem); transition: transform 0.3s ease-in-out;',
        ];
      case (screenWidth1 >= 455 && screenWidth1 < 546):
        return [
          'cursor: pointer; transform:scale(0.6) translateX(-7rem); transition: transform 0.3s ease-in-out;', 'display: inline-block ;transform:translateX(-5rem); transition: transform 0.3s ease-in-out;',
        ];
      case (screenWidth1 >= 341 && screenWidth1 < 455):
        return [
          'cursor: pointer; transform:scale(0.6) ; transition: transform 0.3s ease-in-out;margin-left:-241% ;margin-top:-10%;', 'display: inline-block ;transform: scale(0.8); transition: transform 0.3s ease-in-out;margin-left:-16%',
        ];
      case (screenWidth1 >= 273 && screenWidth1 < 341):
        return [
          'cursor: pointer; transform:scale(0.5) translateX(-9rem); transition: transform 0.3s ease-in-out;', 'display: inline-block ;transform:translateX(-5rem); transition: transform 0.3s ease-in-out;',
        ];
      default:
        return [
          'cursor: pointer; transform:scale(0.8) translateX(1rem) transition: transform 0.3s ease-in-out;', 'display: inline-block ;transform:translateX(1rem) transition: transform 0.3s ease-in-out;',
        ];
    }

  }


  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1240px)' });
  // const handleImageClick = () => {
  //   setIsShifted(!isShifted);
  // }
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1241px)' });
  const companylogo = document.getElementsByClassName("companylogo")[0];
  const hide = document.getElementsByClassName("hide");
  const homebtntxt = document.getElementsByClassName("hometext")[0];

  const sidebar = document.getElementsByClassName("sidebar")[0];
  const container = document.getElementsByClassName("stick")[0];
  const humbergerIcon = document.getElementsByClassName('humbergerIcon')[0];
  const replacetitleimg = document.getElementById('replacetitleimg');
  let permanentdataid, newuserbtn, navbarsidebar;
  const dashboardbtnimgpadding = document.getElementsByClassName('dashboardbtnimgpadding')
  const postjobid = document.getElementById('postjobid');
  ;
  // try {
  permanentdataid = document.getElementById("permanentdataid");
  newuserbtn = document.getElementById("dashboardfont");
  navbarsidebar = document.getElementsByClassName(
    "sidebarBtnForNewUser"
  )[0];

  // const hide = document.getElementsByClassName('hide')[0];
  if (isMobile) {
    if (sidebar) {
      sidebar.style.cssText = "display:none"
    }

    if (humbergerIcon) {

      humbergerIcon.style.cssText = getStylesForWidth(screenWidth)[0]
      // "cursor: pointer;transform: scale(0.8) translateX(-3rem);"
    }
    if (replacetitleimg) {
      if (replacetitleimg.innerText === "Job Posting Preview") {

        replacetitleimg.style.cssText = " display:none;"
      } else {


        replacetitleimg.style.cssText = getStylesForWidth(screenWidth)[1]
        //" display:inline-block;transform: translateX(-3rem)scale(0.7);"
      }

    }


    if (dashboardbtnimgpadding) {
      for (const item of dashboardbtnimgpadding) {
        item.style.cssText = "padding: 3px;transform: scale(0.5);"

      }
    }
    if (container) {
      container.style.cssText = `
    margin-left: -8px;
    border-radius: 38px 0px 0px 0px;
    transition: margin-left 0.5s ease, border-radius 0.5s ease;
`;
    }
  }
  else if (isTabletOrMobile) {
    // const sidebar = document.getElementsByClassName("sidebar")[0];
    if (sidebar) {
      sidebar.style.cssText = "display:none"
    }
    // const companylogo = document.getElementsByClassName("companylogo")[0];
    // const sidebar = document.getElementsByClassName("sidebar")[0];
    // const hide = document.getElementsByClassName("hide");
    // const homebtntxt = document.getElementsByClassName("hometext")[0];
    // const container = document.getElementsByClassName("stick")[0];
    // const humbergerIcon = document.getElementsByClassName('humbergerIcon')[0];
    // const replacetitleimg = document.getElementById('replacetitleimg')
    // let permanentdataid, newuserbtn, navbarsidebar;

    // // try {
    // permanentdataid = document.getElementById("permanentdataid");
    // newuserbtn = document.getElementById("dashboardfont");
    // navbarsidebar = document.getElementsByClassName(
    //   "sidebarBtnForNewUser"
    // )[0];
    // } catch { }
    // if (count % 2 === 0) {
    //   try {
    // newuserbtn.style.display = "none";
    // permanentdataid.style.display = "none";
    // navbarsidebar.style.cssText = "display:none";
    // Apply smooth transition to hide elements
    if (newuserbtn) {
      newuserbtn.style.cssText = `
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease, height 0.5s ease;
  `;
    }
    if (permanentdataid) {
      permanentdataid.style.cssText = `
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease, height 0.5s ease;
  `;
    }
    if (navbarsidebar) {
      navbarsidebar.style.cssText = `
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease, height 0.5s ease;
  `;
    }
    if (humbergerIcon) {

      humbergerIcon.style.cssText = getStylesForWidth(screenWidth)[0]
      // "cursor: pointer;transform: scale(0.8);transform: translateX(-12rem);"
    }
    if (replacetitleimg) {
      replacetitleimg.style.cssText = getStylesForWidth(screenWidth)[1]
      // " display:inline-block;transform: translateX(-12rem);"
    }
    // setTimeout(() => {
    //   newuserbtn.style.display = "none";
    //   permanentdataid.style.display = "none";
    //   navbarsidebar.style.display = "none";
    // }, 500);

    // } catch { }
    // companylogo.style.cssText = `
    //     position: relative;
    //     width: 125px;
    //     height: auto;
    //     transform: scale(0.8);
    //     right: 1rem;
    //     top: 0.6rem;
    //     transition: transform 0.5s ease, top 0.5s ease, right 0.5s ease;
    // `;
    // humbergerIcon.style.cssText = `
    //       transform: translateX(-663%) scale(0.8);
    //       cursor: pointer;
    //       transition: transform 0.5s ease;
    //     `;

    // replacetitleimg.style.cssText = `
    //       display: inline-block;
    //       transform: translate(-11rem);
    //       transition: transform 0.5s ease;
    //     `;

    // for (const dom of hide) {
    //   dom.style.display = "none";
    // }
    // sidebar.style.cssText = "min-min-width:95px;padding-left: 10px; transition: width 0.7s ease, min-width 0.7s ease, padding-left 0.7s ease;";
    if (container) {
      container.style.cssText = `
      margin-left: 0px;
      border-radius: 38px 0px 0px 0px;
      transition: margin-left 0.5s ease, border-radius 0.5s ease;
  `;
    }


    // try {
    if (homebtntxt) {
      homebtntxt.style.cssText = `
      opacity: 0;
      height: 0;
      overflow: hidden;
      transition: opacity 0.5s ease, height 0.5s ease;
  `;
    }


    // }
  } else if (isDesktopOrLaptop) {

    if (sidebar) {
      sidebar.style.cssText = "display:block"
    }
    if (container) {
      container.style.cssText = `
        margin-left: 17rem;
        border-radius: 38px 0px 0px 0px;
        transition: margin-left 0.5s ease, border-radius 0.5s ease;
    `;

    }
    if (humbergerIcon) {
      // console.log(getStylesForWidth(window.innerWidth)[0])
      humbergerIcon.style.cssText = getStylesForWidth(screenWidth)[0]
      //  "cursor: pointer;transform: translateX(1rem) scale(0.8);"
    }
    if (replacetitleimg) {
      replacetitleimg.style.cssText = getStylesForWidth(screenWidth)[1]
      // " display:inline-block;transform: translateX(1rem) ;"
    }

    if (hide) {
      for (const dom of hide) {
        dom.style.cssText = "display:inline-block"
      }

    }
    if (companylogo) {
      companylogo.style.cssText = "position: relative;right: -2rem;transform: scale(1);top: 0.8rem;"
    }
    // if (dashboardbtnimgpadding) {
    //   for (const item of dashboardbtnimgpadding) {
    //     item.style.cssText = "padding: 12px;transform: scale(0.8);"

    //   }
    // }

  }
  // function logScreenWidth() {
  //   console.log("Current screen width: " + window.innerWidth + "px");
  // }

  // window.addEventListener("resize", logScreenWidth);

  // logScreenWidth();

  const toggleSidebar = (event) => {
    event.target.style.cssText = `transform: rotate(${deg}deg);cursor:pointer;padding-top:5px;transform:scale(0.8)`;
    // const companylogo = document.getElementsByClassName("companylogo")[0];
    // const sidebar = document.getElementsByClassName("sidebar")[0];
    // const hide = document.getElementsByClassName("hide");
    // const homebtntxt = document.getElementsByClassName("hometext")[0];
    // const container = document.getElementsByClassName("stick")[0];
    // const humbergerIcon = document.getElementsByClassName('humbergerIcon')[0];
    // const replacetitleimg = document.getElementById('replacetitleimg')
    // let permanentdataid, newuserbtn, navbarsidebar;

    try {
      // permanentdataid = document.getElementById("permanentdataid");
      // newuserbtn = document.getElementById("dashboardfont");
      // navbarsidebar = document.getElementsByClassName(
      //   "sidebarBtnForNewUser"
      // )[0];
    } catch { }
    if (count % 2 === 0) {
      try {
        // newuserbtn.style.display = "none";
        // permanentdataid.style.display = "none";
        // navbarsidebar.style.cssText = "display:none";
        // Apply smooth transition to hide elements
        newuserbtn.style.cssText = `
opacity: 0;
height: 0;
overflow: hidden;
transition: opacity 0.5s ease, height 0.5s ease;
display:none;
`;

        permanentdataid.style.cssText = `
opacity: 0;
height: 0;
overflow: hidden;
transition: opacity 0.5s ease, height 0.5s ease;
`;

        navbarsidebar.style.cssText = `
opacity: 0;
height: 0;
overflow: hidden;
transition: opacity 0.5s ease, height 0.5s ease;
`;

        setTimeout(() => {
          newuserbtn.style.display = "none";
          permanentdataid.style.display = "none";
          navbarsidebar.style.display = "none";
        }, 500);

      } catch { }

      if (isMobile) {
        humbergerIcon.style.cssText = "cursor: pointer;transform: scale(0.6) translateX(0rem);"
        if (replacetitleimg.innerText === "Job Posting Preview") {

          replacetitleimg.style.cssText = " display:none;"
        } else {
          replacetitleimg.style.cssText = " display:inline-block;transform: scale(0.8);margin-left:-15% ; margin-top:2%"
          if (postjobid) {
            postjobid.style.cssText = "display:none"
          }

        }

        // replacetitleimg.style.cssText = " display:none;"
        sidebar.style.cssText = "width: 50px !important;padding-left: 10px; transition: width 0.7s ease, min-width 0.7s ease, padding-left 0.7s ease;";
        companylogo.style.cssText = `
        position: relative;
        width: 60px;
        height: auto;
        transform: scale(0.8);
        right: 0rem;
        top: 0.6rem;
        transition: transform 0.5s ease, top 0.5s ease, right 0.5s ease;
    `;
        for (const items of dashboardbtnimgpadding) {
          items.style.cssText = "padding: 3px;transform: scale(0.5);";

        }
        if (postjobid) {
          postjobid.style.cssText = "display:none"
        }
        //   container.style.cssText = `
        //     margin-left: 0px;
        //     border-radius: 38px 0px 0px 0px;
        //     transition: margin-left 0.5s ease, border-radius 0.5s ease;
        // `;
        container.style.cssText = `
        margin-left: 58px !important;
        border-radius: 38px 0px 0px 0px;
        transition: margin-left 0.5s ease, border-radius 0.5s ease;
    `;
        for (const dom of hide) {
          dom.style.display = "none";
        }
      }
      else if (isTabletOrMobile) {
        humbergerIcon.style.cssText = `
    transform: translateX(-386%) scale(0.8);
    cursor: pointer;
    transition: transform 0.5s ease;
  `;
        replacetitleimg.style.cssText = `
   display: inline-block;
    transform: translate(-9rem);
    transition: transform 0.5s;
    margin-left: 50px;
`;
        sidebar.style.cssText = "min-width:95px; !important padding-left: 10px; transition: width 0.7s ease, min-width 0.7s ease, padding-left 0.7s ease;";
        companylogo.style.cssText = `
        position: relative;
        width: 88px;
        height: auto;
        transform: scale(0.8);
        right: 0rem;
        top: 0.6rem;
        transition: transform 0.5s ease, top 0.5s ease, right 0.5s ease;
    `;
        for (const dom of hide) {
          dom.style.display = "none";
        }
        container.style.cssText = `
        margin-left: 100px;
        border-radius: 38px 0px 0px 0px;
        transition: margin-left 0.5s ease, border-radius 0.5s ease;
    `;
        replacetitleimg.style.cssText = `
 
    display: inline-block;
    transform: translate(-7rem);
    transition: transform 0.5s;
    margin-left: 10px;
`;
      }
      else if (isDesktopOrLaptop) {
        if (replacetitleimg.innerText === "Job Posting Preview") {
          humbergerIcon.style.cssText = `
          transform:  scale(0.8);
          cursor: pointer;
          margin-left:-71%;
          transition: margin-left 0.5s ease;
        `;
        }
        else {
          humbergerIcon.style.cssText = `
        transform:  scale(0.8);
        cursor: pointer;
        margin-left:-117%;
        transition: margin-left 0.5s ease;
      `;
        }

        replacetitleimg.style.cssText = `
 
    display: inline-block;
    // transform: translate(-10.2rem);
    margin-left: -6%;
    transition: margin-left 0.5s;

`;
        sidebar.style.cssText = "min-width:95px; !important padding-left: 10px; transition: width 0.7s ease, min-width 0.7s ease, padding-left 0.7s ease;";
        companylogo.style.cssText = `
        position: relative;
        width: 110px;
        height: auto;
        transform: scale(0.8);
        right: 1rem;
        top: 0.6rem;
        transition: transform 0.5s ease, top 0.5s ease, right 0.5s ease;
    `;
        for (const dom of hide) {
          dom.style.display = "none";
        }
        container.style.cssText = `
    margin-left: 100px;
    border-radius: 38px 0px 0px 0px;
    transition: margin-left 0.5s ease, border-radius 0.5s ease;
`; if (homebtntxt) {
          homebtntxt.style.cssText = `
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.5s ease, height 0.5s ease;
  `;

          setTimeout(() => {
            homebtntxt.style.display = "none";
          }, 500);
        }

      }







      try {



      } catch { }
      deg += 90;

    } else {
      try {

        // document.getElementsByClassName("innerhide")[0].style.cssText = "18px"
        newuserbtn.style.cssText = `
display: inline-block;
opacity: 1;
color:white;
transition: opacity 0.2s ease;
`;

        permanentdataid.style.cssText = `
display: inline-block;
opacity: 0;
transition: opacity 0.2s ease;
`;

        navbarsidebar.style.cssText = `
border-radius: 16px;
background-color: #F9F9F9;
transition: border-radius 0.2s ease, background-color 0.2s ease;
`;

        setTimeout(() => {
          newuserbtn.style.opacity = 1;
          permanentdataid.style.opacity = 1;
        }, 100);

      } catch { }
      //       `
      // transform: translateX(12%) scale(0.8);
      // cursor: pointer;
      // transition: transform 0.2s ease;
      // `;

      companylogo.style.cssText = `
      position: relative !important;
      right: -2rem;
      transform: scale(1); 
      top: 0.8rem;
      transition: right 0.2s ease, transform 0.2s ease, top 0.2s ease;
  `;

      // setTimeout(() => {
      //   companylogo.style.transform = 'scale(1.2)';
      if (isMobile) {
        sidebar.style.cssText = "display:none"


        humbergerIcon.style.cssText = getStylesForWidth(screenWidth)[0]
        //  "cursor: pointer;transform: scale(0.8) translateX(-3rem);"

        // replacetitleimg.style.cssText = " display:inline-block;transform: translateX(-3rem);"

        if (replacetitleimg.innerText === "Job Posting Preview") {

          replacetitleimg.style.cssText = " display:none;"
        } else {


          replacetitleimg.style.cssText = getStylesForWidth(screenWidth)[1]
          //  " display:inline-block;transform: translateX(-3rem)scale(0.7);"
        }

        container.style.cssText = `
        margin-left: -8px;
        border-radius: 38px 0px 0px 0px;
        transition: margin-left 0.5s ease, border-radius 0.5s ease;`
        if (postjobid) {
          postjobid.style.cssText = "display:inline-block"
        }
      }
      // }, 500);
      else if (isTabletOrMobile) {
        // sidebar.style.cssText = `
        // min-width: 280px;
        // transition: min-width 0.5s ease;
        // `;
        // container.style.cssText = `
        // margin-left: 260px;
        // border-radius: 38px 0px 0px 0px;
        // transition: margin-left 0.5s ease, border-radius 0.5s ease;
        // `;
        sidebar.style.cssText = 'display:none'

        container.style.cssText = `
          margin-left: 0px;
          border-radius: 38px 0px 0px 0px;
          transition: margin-left 0.5s ease, border-radius 0.2s ease;
      `;


        humbergerIcon.style.cssText = "cursor: pointer;transform: scale(0.8) translateX(-15rem);"

        replacetitleimg.style.cssText = " display:inline-block;transform: translateX(-12rem);"



      } else {
        sidebar.style.cssText = `
min-width: 280px;
transition: min-width 0.2s ease;
`;

        container.style.cssText = `
margin-left: 265px;
border-radius: 38px 0px 0px 0px;
transition: margin-left 0.2s ease, border-radius 0.5s ease;
`;
        humbergerIcon.style.cssText = getStylesForWidth(screenWidth)[0]

        replacetitleimg.style.cssText = getStylesForWidth(screenWidth)[1]
        //          `
        // display: inline-block;
        // transform: translateX(0);
        // transition: transform 0.2s ease;
        // `;

      }

      for (const dom of hide) {
        //  dom.style.cssText = `
        //   display: inline-block;
        //    transform: scaleX(0);
        //   transform-origin: left center;
        //   overflow: hidden;
        //  transition: transform 0.5s ease;
        //`;

        //   setTimeout(() => {
        //    dom.style.transform = 'scaleX(1)';
        //  }, 100);



        dom.style.cssText = `
        display: inline-block;
        opacity: 0;
        height:'20px'!important;
        overflow: hidden;
        margin-bottom:-2%;
        transition: opacity 0.2s ease, height 0.2s ease;
        
        `;

        setTimeout(() => {
          dom.style.opacity = 1;
          dom.style.height = '20px';
        }, 0);
        //         dom.style.cssText = `
        // display: inline-block;
        // `;

        // setTimeout(() => {

        //   dom.style.height = '18px';
        // }, 10);
      }
      try {
        homebtntxt.style.cssText = `
display: inline-block;
opacity: 0;
height: 20px;
overflow: hidden;
 margin-bottom:-2%;
transition: opacity 0.2s ease, height 0.2s ease;
`;

        setTimeout(() => {
          homebtntxt.style.opacity = 1;
          homebtntxt.style.height = '20px';
        }, 100);

      } catch { }
      deg -= 90;
    }
    count += 1;
  };

  // notification
  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);

    try {



      // if (textElement && buttonElement) {
      //   observer.observe(buttonElement);
      // }

      if (props.message.length !== 0) {
        setMatch(props.message);
      }
    } catch {
      return () => window.removeEventListener('resize', handleResize);
    }
  }, [props.message]);

  async function countRead() {
    const response = await markasReadNotifications(token);
  }
  // const isActiveed;
  const handleToggleClick = (e) => {
    // setIsActive(prev => !prev);
    toggleSidebar(e);
    // document.getElementById('sidebaricon').cssText=" translateX(-663%)"
    // is
  }
  return (
    <div className="row align-items-center align-items-top-custom-screen pl-5 mt-lg-1 mt-0 scroll ml-1 ml-sm-0">
      {/* First Column */}
      <div className="col-lg-2 col-md-2 col-12">
        {/* Content for the first column */}
      </div>

      {/* Second Column */}
      {/* <div className="row"> */}
      <div className="col-lg-7 col-md-5 col-6 mt-lg-0 mt-4 ml-lg-0 ml-2 d-flex align-items-center justify-content-between">
        <div className="navbartopbarbothimageandicon">
          <img
            src="/icon/common/dashline.png"
            alt="dashline"
            onClick={(e) => handleToggleClick(e)}
            style={{ cursor: "pointer", transform: "scale(0.8)" }}
            className="pb-2 humbergerIcon"
            id="sidebaricon"
          />
          {isrecruiter && !isnewuser ? (
            <select
              className="navbartopbarselectrole"
              onChange={(event) => history.push(event.target.value)}
            >
              {props.profilelocation.split("/")[1] === "client" ? (
                <>
                  <option value="/client/dashboardview">Client's Dashboard</option>
                  <option value="/candidate/dashboardview">Candidate's Dashboard</option>
                </>
              ) : (
                <>
                  <option value="/candidate/dashboardview">Candidate's Dashboard</option>
                  <option value="/client/dashboardview">Client's Dashboard</option>
                </>
              )}
            </select>
          ) : (
            <div className="navBar-TopBar-dashboard-text">
              <font className="pl-4 font-size-16px" id="replacetitleimg">
                Dashboard
              </font>
            </div>
          )}
        </div>
        <div>
          {isrecruiter && !isnewuser && props.profilelocation.split("/")[1] === "candidate" && (
            <div className="float-center pt-2">
              <Link to="/candidate/registration" className="btn postjobbtn">
                Upload Profile
              </Link>
            </div>
          )}
          {props.profilelocation.split("/")[1] === "client" && (
            <div className="float-center pt-1 mr-3" id="postjobid">
              <Link to="/client/uploadresume" className="btn postjobbtn">
                Post A Job
              </Link>
            </div>
          )}
        </div>
      </div>

      {/* Third Column */}
      <div className="col-lg-3 col-md-4 col-5 d-flex justify-content-end align-items-center popbox">
        {props.count !== 0 ? (
          <Popup
            trigger={
              <div className="position-relative">
                {props.count !== 0 && (
                  <div className="badge badge-primary position-absolute" style={{ top: "-10px", right: "-10px" }}>
                    {props.count}
                  </div>
                )}
                <span className="navBar-TopBar-bell-icon">
                  <img
                    src="/icon/common/bell-icon.svg"
                    alt="bell"
                    height={20}
                    width={20}
                    className="cursor-pointer"
                  />
                </span>
              </div>
            }
            position="bottom right"
            contentStyle={{
              width: "100%",
              maxWidth: "389px",
              background: "#ffffff",
              borderRadius: "10px",
            }}
            ref={viewref}
          >
            {match.hasOwnProperty("notifications") &&
              match.notifications.slice(0, 4).map((data, index) => (
                <div key={index} className="px-3 py-2">
                  <div className="row">
                    <div className="col-1">
                      <i className="fas fa-user-circle navBar-TopBar-fa-icon"></i>
                    </div>
                    <div className="col">
                      <label className="messagetopbar font-weight-500 colored-charcoal-gray navBar-TopBar-msg-label">
                        {data.message.replace(/<[^>]+>/g, "")}
                      </label>
                    </div>
                  </div>
                  {index < 3 && <hr className="divider" />}
                </div>
              ))}
            <Link
              to={props.allnotification}
              onClick={() => {
                viewref.current.close();
                countRead();
              }}
              className="text-decoration-none"
            >
              <div className="text-primary text-center font-weight-bold mt-2">
                View All
              </div>
            </Link>
          </Popup>
        ) : (
          <Link to={props.allnotification}>
            <span className="navBar-TopBar-bell-icon">
              <img
                src="/icon/common/bell-icon.svg"
                alt="bell"
                height={20}
                width={20}
              />
            </span>
          </Link>
        )}

        <Popup
          trigger={
            props.profilePicture ? (
              <div className="d-flex align-items-center ml-3">
                <img
                  src={props.profilePicture || "/icon/common/darkprofile.png"}
                  alt="user"
                  className="navBar-TopBar-user-profile-css"
                // style={{ width: "80px", height: "80px", borderRadius: "50%" }}
                />
                <div className="ml-2 mobile-hidden">
                  <div className="font-weight-bold font-size-16 navBar-TopBar-circle-First-Last-Name">
                    {props.firstName}&nbsp;{props.lastName}
                  </div>
                  <div className="colored-Ash-gray font-size-12">
                    {props.candidateId}
                  </div>
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center ml-3">
                <figure className="story_shape story_shape_final mb-1">
                  <img
                    src="/icon/common/darkprofile.png"
                    alt="person on a tour"
                    className="story_img"
                  // style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                  />
                  <figcaption className="story_caption">
                    {props.firstName.charAt(0)}
                    {props.lastName.charAt(0)}
                  </figcaption>
                </figure>
                <div className="ml-2 mobile-hidden">
                  <div className="font-weight-bold font-size-16 navBar-TopBar-circle-First-Last-Name">
                    {props.firstName}&nbsp;{props.lastName}
                  </div>
                  <div className="colored-Ash-gray font-size-12">
                    {props.candidateId}
                  </div>
                </div>
              </div>
            )
          }
          position="bottom right"
          contentStyle={{
            width: "197px",
            height: "126px",
            background: "#EAEAEA",
            borderRadius: "10px",
          }}
          ref={ref}
        >
          <div className="p-3 d-flex flex-column">
            <Link className="tab" to={props.profilelocation} onClick={() => ref.current.close()}>
              <label className="cursor-pointer font-size-18px font-weight-500 font-family-roboto colored-primary-black">
                Profile
              </label>
            </Link>
            <Link className="tab" to={props.settinglocation} onClick={() => ref.current.close()}>
              <label className="cursor-pointer font-size-18px font-weight-500 font-family-roboto colored-primary-black">
                Settings
              </label>
            </Link>
            <Link className="tab" to="/user/login" onClick={resetToken}>
              <label className="cursor-pointer font-size-18px font-weight-500 font-family-roboto colored-primary-black">
                Logout
              </label>
            </Link>
          </div>
        </Popup>
      </div>
      {/* </div> */}
      {/* <div className="col-lg-3 col-md-4 col-12 d-flex justify-content-end popbox">
        {props.count !== 0 ? (
          <Popup
            trigger={
              <div>
                {props.count !== 0 && <div className="notifybadges bg-primary">{props.count}</div>}
                <span className="navBar-TopBar-bell-icon">
                  <img
                    src="/icon/common/bell-icon.svg"
                    alt="bell"
                    height={20}
                    width={20}
                    className="cursor-pointer"
                  />
                </span>
              </div>
            }
            position="bottom right"
            contentStyle={{
              width: "389px",
              height: "auto",
              background: "#ffffff",
              borderRadius: "10px",
            }}
            ref={viewref}
          >
            {match.hasOwnProperty("notifications") &&
              match.notifications.slice(0, 4).map((data, index) => (
                <div key={index}>
                  <div className="row">
                    <div className="col-1">
                      <i className="fas fa-user-circle navBar-TopBar-fa-icon"></i>
                    </div>
                    <div className="col">
                      <label className="messagetopbar font-weight-500 colored-charcoal-gray navBar-TopBar-msg-label">
                        {data.message.replace(/<[^>]+>/g, "")}
                      </label>
                    </div>
                  </div>
                  <hr className="divider" />
                </div>
              ))}
            <Link
              to={props.allnotification}
              onClick={() => {
                viewref.current.close();
                countRead();
              }}
              className="text-decoration-none"
            >
              <div className="text-primary text-center font-weight-bold mt-2">
                View All
              </div>
            </Link>
          </Popup>
        ) : (
          <Link to={props.allnotification}>
            <span className="navBar-TopBar-bell-icon">
              <img
                src="/icon/common/bell-icon.svg"
                alt="bell"
                height={20}
                width={20}
              />
            </span>
          </Link>
        )}

        <Popup
          trigger={
            props.profilePicture ? (
              <div className="row align-items-center">
                <img
                  src={props.profilePicture || "/icon/common/darkprofile.png"}
                  alt="user"
                  className="ml-3 navBar-TopBar-user-profile-css"
                />
                <div className="col-md col-sm-6 col-6">
                  <div className="col-md d-flex align-item-center font-weight-600 font-size-16px navBar-TopBar-circle-First-Last-Name">
                    {props.firstName}&nbsp;{props.lastName}
                  </div>
                  <div className="row">
                    <div className="col-md">
                      <font className="colored-Ash-gray font-weight-500 font-size-12px ml-3">
                        {props.candidateId}
                      </font>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="row align-items-center persononatour">
                <figure className="story_shape mb-1">
                  <img
                    src="/icon/common/darkprofile.png"
                    alt="person on a tour"
                    className="story_img"
                  />
                  <figcaption className="story_caption">
                    {props.firstName.charAt(0)}
                    {props.lastName.charAt(0)}
                  </figcaption>
                </figure>
                <div className="col-md col-sm-6 col-6 candidatefirstNameLastName">
                  <div className="col-md d-flex align-item-center navBar-TopBar-circle-First-Last-Name font-size-16px font-weight-600">
                    {props.firstName}&nbsp;{props.lastName}
                  </div>
                  <div className="row">
                    <div className="col-md">
                      <font className="colored-Ash-gray font-size-12px font-weight-500 ml-3">
                        {props.candidateId}
                      </font>
                    </div>
                  </div>
                </div>
              </div>
            )
          }
          position="bottom right"
          contentStyle={{
            width: "197px",
            height: "126px",
            background: "#EAEAEA",
            borderRadius: "10px",
          }}
          ref={ref}
        >
          <div className="p-3 d-flex flex-column">
            <Link className="tab" to={props.profilelocation} onClick={() => ref.current.close()}>
              <label className="cursor-pointer font-size-18px font-weight-500 font-family-roboto colored-primary-black">
                Profile
              </label>
            </Link>
            <Link className="tab" to={props.settinglocation} onClick={() => ref.current.close()}>
              <label className="cursor-pointer font-size-18px font-weight-500 font-family-roboto colored-primary-black">
                Settings
              </label>
            </Link>
            <Link className="tab" to="/user/login" onClick={resetToken}>
              <label className="cursor-pointer font-size-18px font-weight-500 font-family-roboto colored-primary-black">
                Logout
              </label>
            </Link>
          </div>
        </Popup>
      </div> */}
    </div>


    // <div className={`row align-items-center  pl-5 mt-1 scroll  ${isShifted ? "shifted" : ""}`}>
    // <div className={`row align-items-center  pl-5 mt-1 scroll ml-1 ml-sm-0 `}>

    //   <div className={"col-lg-2 col-md-3 col-12"}></div>
    //   <div className={"col-lg-7 col-md-5 col-sm-12 col-12 mt-lg-0 mt-4 ml-lg-0 ml-2"}>
    //     <img
    //       src="/icon/common/dashline.png"
    //       alt={"dashline"}
    //       onClick={(e) => handleToggleClick(e)}

    //       style={{ cursor: "pointer", transform: 'scale(0.8)' }}
    //       className={"pb-2 humbergerIcon"}
    //       id={"sidebaricon"}
    //     />
    //     {/*below is condition if recruiter come select will return else div */}
    //     {isrecruiter && isnewuser === false ? (
    //       <select
    //         className={"navbartopbarselectrole"}
    //         onChange={(event) => history.push(event.target.value)}
    //       >
    //         {props.profilelocation.split("/")[1] === "client" ? (
    //           <>
    //             <option value={"/client/dashboardview"}>
    //               Client's Dashboard
    //             </option>
    //             <option value={"/candidate/dashboardview"}>
    //               Candidate's Dashboard
    //             </option>
    //           </>
    //         ) : (
    //           <>
    //             <option value={"/candidate/dashboardview"}>
    //               Candidate's Dashboard
    //             </option>
    //             <option value={"/client/dashboardview"}>
    //               Client's Dashboard
    //             </option>
    //           </>
    //         )}
    //       </select>
    //     ) : (
    //       <div className="navBar-TopBar-dashboard-text" >
    //         <font
    //           className={"pl-4 font-size-16px "}
    //           id={"replacetitleimg"}
    //         >
    //           Dashboard
    //         </font>
    //       </div>
    //     )}

    //     {/*upload profile button show only in recruiter & freelance recruiter dashboardview*/}
    //     {isrecruiter && isnewuser === false && (
    //       <>
    //         {props.profilelocation.split("/")[1] === "candidate" ? (
    //           <>
    //             <div className="float-right pt-2">
    //               <Link
    //                 to={"/candidate/registration"}
    //                 className={"btn postjobbtn"}
    //               >
    //                 Upload Profile
    //               </Link>
    //             </div>
    //           </>
    //         ) : (
    //           <></>
    //         )}
    //       </>
    //     )}
    //     {/*post job button show only in client dashboardview*/}
    //     {props.profilelocation.split("/")[1] === "client" ? (
    //       <div className="float-right pt-1 mr-3" id={"postjobid"}>
    //         <Link to={"/client/uploadresume"} className={"btn postjobbtn"}>
    //           Post A Job
    //         </Link>
    //       </div>
    //     ) : (
    //       <></>
    //     )}
    //   </div>
    //   <div className={"col-md-3 col-sm-4 col-3 popbox"}>
    //     <div className="row">
    //       <div className={"col-md-3 d-flex align-items-center"}>
    //         {props.count !== 0 ? (
    //           <>
    //             <Popup
    //               trigger={
    //                 <div>
    //                   {props.count !== 0 ? (
    //                     <div className="notifybadges bg-primary">{props.count}</div>
    //                   ) : (
    //                     ""
    //                   )}
    //                   <span className="navBar-TopBar-bell-icon" >
    //                     <img
    //                       src="/icon/common/bell-icon.svg"
    //                       alt={"bell"}
    //                       height={20}
    //                       width={20}
    //                       className="cursor-pointer"
    //                     />
    //                   </span>
    //                 </div>
    //               }
    //               position="bottom right"
    //               contentStyle={{
    //                 width: "389px",
    //                 height: "auto",
    //                 background: "#ffffff",
    //                 borderRadius: "10px",
    //               }}
    //               ref={viewref}
    //             >
    //               {match.hasOwnProperty("notifications") &&
    //                 match.notifications.slice(0, 4).map((data, index) => (
    //                   <div key={index}>
    //                     <div></div>
    //                     <div className="row">
    //                       <div className="col-1">
    //                         <i
    //                           className="fas fa-user-circle navBar-TopBar-fa-icon"
    //                         ></i>
    //                       </div>
    //                       <div className="col">
    //                         <label
    //                           className="messagetopbar font-weight-500 colored-charcoal-gray navBar-TopBar-msg-label"
    //                         >
    //                           {data.message.replace(/<[^>]+>/g, "")}
    //                         </label>
    //                       </div>
    //                     </div>
    //                     <hr className={"divider"} />
    //                   </div>
    //                 ))}
    //               <Link
    //                 to={`${props.allnotification}`}
    //                 onClick={() => {
    //                   viewref.current.close();
    //                   countRead();
    //                 }}
    //                 className="text-decoration-none"
    //               >
    //                 <div
    //                   className="text-primary text-center font-weight-bold mt-2"
    //                 >
    //                   View All
    //                 </div>
    //               </Link>
    //             </Popup>
    //           </>
    //         ) : (
    //           <Link to={`${props.allnotification}`}>
    //             <span className="navBar-TopBar-bell-icon " >
    //               <img
    //                 src="/icon/common/bell-icon.svg"
    //                 alt={"bell"}
    //                 height={20}
    //                 width={20}
    //               />
    //             </span>
    //           </Link>
    //         )}
    //       </div>
    //       <div className={"col-md-9"}>
    //         <Popup
    //           trigger={
    //             props.profilePicture ? (
    //               <div className={"row align-items-center"}>
    //                 <img
    //                   src={
    //                     props.profilePicture
    //                       ? props.profilePicture
    //                       : "/icon/common/darkprofile.png"
    //                   }
    //                   alt={"user"}
    //                   className={"ml-3 navBar-TopBar-user-profile-css"}
    //                 />
    //                 <div className={"col-md col-sm-6 col-6"}>
    //                   <div className={"col-md d-flex align-item-center font-weight-600 font-size-16px navBar-TopBar-circle-First-Last-Name"}>
    //                     <div
    //                     >
    //                       {props.firstName}&nbsp;{props.lastName}
    //                     </div>
    //                     <br />
    //                   </div>
    //                   <div className={"row"}>
    //                     <div className={"col-md"}>
    //                       <font
    //                         className="colored-Ash-gray font-weight-500 font-size-12px ml-3"                          >
    //                         {props.candidateId}
    //                       </font>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>

    //             ) : (
    //               <div className={"row align-items-center persononatour"}>
    //                 <figure className="story_shape mb-1">
    //                   <img
    //                     src="/icon/common/darkprofile.png"
    //                     alt="person on a tour"
    //                     className="story_img"
    //                   />
    //                   <figcaption className="story_caption">
    //                     {props.firstName.charAt(0)}
    //                     {props.lastName.charAt(0)}
    //                   </figcaption>
    //                 </figure>
    //                 <div className={"col-md col-sm-6 col-6 candidatefirstNameLastName"}>
    //                   <div className={"col-md d-flex align-item-center navBar-TopBar-circle-First-Last-Name font-size-16px font-weight-600"}>
    //                     <div
    //                     >
    //                       {props.firstName}&nbsp;{props.lastName}
    //                     </div>
    //                     <br />
    //                   </div>
    //                   <div className={"row"}>
    //                     <div className={"col-md"}>
    //                       <font
    //                         className="colored-Ash-gray font-size-12px font-weight-500 ml-3"
    //                       >
    //                         {props.candidateId}
    //                       </font>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </ div>
    //             )
    //           }
    //           position="bottom right"
    //           contentStyle={{
    //             width: "197px",
    //             height: "126px",
    //             background: "#EAEAEA",
    //             borderRadius: "10px",
    //           }}
    //           ref={ref}
    //         >
    //           <div
    //             className={"p-3 d-flex flex-column"}

    //           >
    //             <Link className="tab"
    //               to={props.profilelocation}
    //               onClick={() => ref.current.close()}
    //             >
    //               <label
    //                 className="cursor-pointer font-size-18px font-weight-500 font-family-roboto  colored-primary-black"

    //               >
    //                 Profile
    //               </label>
    //             </Link>
    //             <Link className="tab"
    //               to={props.settinglocation}
    //               onClick={() => ref.current.close()}
    //             >
    //               <label
    //                 className="cursor-pointer font-size-18px font-weight-500 font-family-roboto  colored-primary-black"
    //               >
    //                 Settings
    //               </label>
    //             </Link>
    //             <Link className="tab" to={"/user/login"} onClick={resetToken}>
    //               <label
    //                 className="cursor-pointer font-size-18px font-weight-500 font-family-roboto  colored-primary-black"

    //               >
    //                 Logout
    //               </label>
    //             </Link>
    //           </div>
    //         </Popup>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}