import React from "react";
import { Link } from "react-router-dom";
import "../styles/app.scss";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { notification } from "../api/common/notification";
import { current } from "@reduxjs/toolkit";
import { ContactSupportOutlined } from "@material-ui/icons";

const Notification = (props) => {
  const token = useSelector((state) => state.token);
  const [match, setMatch] = useState([]);
  const [totalLength, setTotalLength] = useState();
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    async function callNotification() {
      try {
        const response = await notification(token, 1);
        if (response.status === 200) {
          const data = await response.json();
          const payload = data.payload;
          if (payload.hasOwnProperty("totalCount")) {
            setTotalLength(payload.totalCount);
            setPageCount(Math.ceil(payload.totalCount / 6));
          }
          if (payload.totalCount !== 0) {
            setMatch(payload.notifications);
          }
        }
      } catch {}
    }
    callNotification();
  }, []);

  // pagination
  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    const response = await notification(token, currentPage);
    if (response.status === 200) {
      const data = await response.json();
      const payload = data.payload;
      setMatch(payload.notifications);
    }
  };

  return (
    <>
      {totalLength !== 0 ? (
        <>
          <div className={"col mx-5 mt-5 "}>
            <div className="w-100 pt-4 pb-4 px-5 contentholder">
              {match.map((data, index) => (
                <div key={index} className="notification-card mb-3">
                  <div className="row mt-2">
                    <div className="col-10">
                      <div className="d-flex">
                        {/* <label>
                          <Link
                            onClick={() => {
                              handleNotifications(data.unreadMessages);
                            }}
                            to={props.viewdetails}
                            style={{
                              fontWeight: "500",
                              fontSize: "15px",
                              padding: "0px 10px 0px 50px",
                              textDecoration: "none",
                              color: `${data.read ? "black" : "blue"}`,
                            }}
                          >
                          </Link>
                        </label> */}
                        {/* <label
                          style={{
                            fontWeight: "500",
                            fontSize: "15px",
                            padding: "0px 10px 0px 50px",
                            textDecoration: "none",
                          }}
                        >
                          {data.message.replace(/<[^>]+>/g, "")}
                        </label> */}
                        <div className="row">
                          <div className="col-1 mt-2">
                            <i
                              className = "fas fa-user-circle colored-sky-blue Notification-icons-css"
                          
                            ></i>
                          </div>
                          <div className="col">
                            <label
                              className="message colored-charcoal-gray font-size-15px font-weight-500 Notification-message-css"
                          
                            >
                              {data.message.replace(/<[^>]+>/g, "")}
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"col-2"}>
                      <label className={"time"}>
                        {new Date(data.createdAt).toLocaleDateString("en-US")}
                        &nbsp;,&nbsp; &nbsp;
                        {new Date(data.createdAt).toLocaleTimeString("en-US", {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </label>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <div className={"center display pt-5 pb-5"}>
          No notifications to display
        </div>
      )}

      <div className={"col-12 mt-5 pb-5"}>
        <div className={"row align-items-center pb-5"}>
          <div className={"col-md-6 pl-0 pb-5"}></div>
          <div className={"col-md"}>
            {totalLength > 6 && (
              <ReactPaginate
                previousLabel={"<< Previous"}
                nextLabel={"Next >>"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={3}
                pageRangeDisplayed={3}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Notification);
